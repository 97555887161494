.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;

  &.green {
    background-color: @mainGreenColor;
  }

  &.red {
    background-color: #ec434c;
  }

  &.grey {
    background-color: #c0d5e4;
  }

  &.yellow {
    background-color: #f9df45;
  }

  &.blue {
    background-color: #587999;
  }

  &.orange {
    background-color: #ef993f;
  }

  &.halfred {
    background: linear-gradient(to right, #ec434c 50%, white 50%);
    border: 2px solid #ec434c;
  }
}

.ant-select,
.ant-select-item {
  .dot {
    margin-right: 5px;
    vertical-align: -2px;
  }
}
