.notifications-wrapper {
  max-width: 420px;
  min-width: 320px;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;

  .notifications {
    max-height: 392px;
    overflow-y: scroll;
  }
  .notification {
    font-size: 10px;
    background-color: #f0f4f6;
    opacity: 0.5;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #f0f4f6;

    &--unread {
      background-color: #fff;
      opacity: 1;
    }

    .icon-wrapper {
      text-align: center;
    }

    .content {
      color: #3b4c60;
      margin-right: 10px;

      .time {
        color: #587999;
      }
    }

    .fault-actions {
      margin-top: 5px;
      margin-bottom: 5px;
      .ant-btn {
        font-size: 12px;
      }
    }
  }

  .actions {
    padding: 20px;
  }
}

.notifications-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
}

// .nottification-icon-wrapper {
//   background-image: url('../../static/images/notification-full.png');
//   background-position: top left;
//   background-repeat: no-repeat;
//   width: 24px;
//   height: 24px;
//   vertical-align: middle;
// }

.header-notifications-button:hover {
  opacity: 0.8;
}

.notifications-arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;

  .notifications-arrow {
    color: #c0d6e5;

    &:hover {
      cursor: pointer;
      color: #3b4c60;
    }
  }
}
