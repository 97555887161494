.activate-template-wrapper {
  border: 1px dashed rgb(192, 214, 229);
  border-radius: 20px;
  padding: 30px;
  text-align: center;

  .activate-template-icon {
    color: #ec434c;
    font-size: 20px;
  }

  .activate-template-info {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .activate-btn,
  .activate-btn:active,
  .activate-btn:focus {
    color: #ec434c;
    background-color: #fdeced;
    border: 0;
    font-weight: 'bold';
    font-size: 16px;

    height: 48px;
    width: 178px;
  }

  .activate-btn:hover {
    background-color: darken(#fdeced, 5%);
    color: #ec434c;
  }
}

.settlement-template-wrapper {
  background-color: #3b4c60;
  border-radius: 20px;
  padding: 40px;

  .settlement-template-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .settlement-template-title {
      color: #b1cade;
      font-size: 24px;
      margin-right: 20px;

      .title {
        display: inline-block;
      }

      .settlement-template-period {
        color: #ffffff;
        margin-left: 5px;
        margin-right: 5px;
        display: inline-block;
      }

      .settlement-template-issue-date {
        color: #b1cade;
        display: inline-block;
      }
    }

    .settlement-template-archives-btn {
      background-color: #252d38;
      color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      border: 0;

      height: 24px;
      width: 108px;
    }
  }

  .settlement-info-wrapper {
    .settlement-info-title {
      color: #b1cade;
      font-size: 18px;
      margin-bottom: 30px;
      margin-top: 50px;
    }

    .settlement-recipient-info {
      color: #b1cade;
      font-size: 12px;
    }

    .settlement-recipient-value {
      color: #fff;
      font-size: 18px;
      text-overflow: ellipsis;
      overflow: hidden;

      .settlement-recipient-value-copy {
        color: #587999;
        margin-top: 3px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .settlement-payment-info {
      color: #b1cade;
      font-size: 12px;
    }
  }

  .settlement-summary {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-right: 100px;
    text-align: right;

    .settlement-summary-label {
      color: #b1cade;
      font-size: 24px;
      margin-right: 15px;
    }

    .settlement-summary-value {
      color: #ffffff;
      font-size: 24px;
    }
  }

  .settlement-annotation {
    color: #587999;
    font-size: 10px;
  }
}

.settlement-costs-table {
  background-color: #3b4c60;

  .ant-table {
    background-color: #3b4c60;
  }

  .ant-table-container {
    overflow-x: auto;
    border-radius: 0;
    padding-bottom: 15px;
    background-color: #3b4c60;

    table {
      background-color: #3b4c60;
      thead {
        tr:first-child {
          th:first-child {
            border-top-left-radius: 0;
          }
          th:last-child {
            border-top-right-radius: 0;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        border: 0;
        color: #ffffff;
        font-size: 12px;
        height: 24px;
        padding: 0;
        padding-left: 16px;
      }

      &:nth-child(odd) {
        td {
          background-color: rgba(37, 45, 56, 0.2);

          &:first-child {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }

          &:last-child {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }

      &:nth-child(even) {
        td {
          background-color: #3b4c60;
        }
      }
    }
  }

  .ant-table-thead {
    tr {
      th {
        background-color: #3b4c60;
        color: #b1cade;
        font-size: 12px;
        border: 0;
      }
    }
  }

  .ant-table-empty {
    .ant-table-tbody {
      tr {
        td {
          padding-left: 0 !important;
        }
      }
    }
  }
}

.settlement-transfer-info-wrapper {
  .settlement-recipient-info {
    font-size: 12px;
    color: #b1cade;
  }

  .settlement-payment-info {
    font-size: 12px;
    color: #b1cade;
  }

  .settlement-recipient-value {
    font-size: 18px;
    color: #3b4c60;
  }

  .settlement-payment-value {
    font-size: 18px;
    color: #3b4c60;
  }

  .settlement-recipient-value-copy {
    color: #b1cade;
    margin-left: 5px;
    cursor: pointer;

    i::before {
      margin-bottom: 2px;
    }
  }
}

.settlement-transaction-icon {
  font-size: 20px;
  color: #587999;

  &:hover {
    color: #3b4c60;
  }
}

.settlement-archives-datepicker {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
  position: absolute;
}

.settlement-archives-datepicker-popup {
  .ant-picker-cell-disabled::before {
    background: none;
  }

  .ant-picker-cell-disabled {
    .ant-picker-cell-inner {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.ant-modal-header {
  .ant-modal-title {
    .modal-title {
      color: #5353f4;
    }
  }
}

.duplicate-button {
  background-color: transparent;
  font-size: 32px;
  color: #c0d5e4;
  border: 0;
  padding: 0 !important;

  &:hover,
  &:focus,
  &:active {
    color: #587999;
    background-color: transparent;
    border: 0;
  }

  i {
    &::before {
      vertical-align: baseline;
    }
  }
}

.show-import-icon {
  font-size: 32px;
  cursor: pointer;

  &::before {
    margin-top: -10px;
  }

  &:hover {
    &::before {
      color: #3b4c60;
    }
  }
}

@media only screen and (max-width: 992px) {
  .settlement-template-wrapper {
    .settlement-summary {
      margin-right: 50px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .settlement-template-wrapper {
    .settlement-template-header {
      display: block;
    }

    .settlement-summary {
      margin-right: 0px;
    }
  }
}
