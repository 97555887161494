.stats {
  &-elem {
    border: 1px solid #F0F4F6;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;

    .title {
      h2 {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .content {
      .form {
        overflow: hidden;

        form {
          float: right;
        }
      }

      .stats-elem-premises-legend {
        max-width: 300px;
        margin: 10% auto;

        .legend-item {
          margin-bottom: 10px;
        }

        .dot {
          margin-right: 5px;
          vertical-align: -2px;

          &.premises {
            background-color: @mainBlueColor;
          }

          &.services {
            background-color: @mainGreenColor;
          }

          &.parking {
            background-color: #ED565E;
          }

          &.storage {
            background-color: #F0F4F6;
          }
        }
      }
    }
    @media only screen and (min-width: 576px) {
      .content {
        padding: 40px;
      }
    }
  }
}