.rooms {

  &-add-room {
    margin-bottom: 10px;
  }
  &-list {
    &-room {
      background-color: #F0F4F6;
      border-radius: 20px;
      margin-bottom: 10px;
      padding: 20px;

      &-name {
        font-weight: bold;
        font-size: 16px;
      }

      &-settings {
        text-align: right;

        .ant-btn {
          background: transparent !important;
          color: #C0D5E4;

          &:hover,
          &:active {
            color: rgba(0, 0, 0, 0.85)
          }
          &::after {
            box-shadow: none;
            border: none;
          }
        }

        .icon-more-vertical::before {
          transform: rotate(90deg);
        }
      }
    }
  }
}