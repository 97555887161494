.tenantChanges {
  &-edit {
    .attachments {
      .smalllist-wrapper-row-icon {
        font-size: 16px;
      }
    }
  }

  &-show {
    .faults-notification {
      width: 100%;
      margin-bottom: 10px;

      .ant-alert {
        background-color: #D5D5F3;
        border: none;
        padding: 20px;
        border-radius: 20px;

        &-message {
          color: @mainBlueColor;

          .font-bold {
            font-weight: bold;
          }
        }

        &-action {
          .confirm-fault-button {
            margin-right: 10px;
          }
        }
      }
    }
    .description-box,
    .comment-box {
      .content {
        padding: 20px 35px;
        white-space: pre-wrap;
      }
    }
    .description-box {
      background-color: @mainBlueColor;
      color: #fff;

      h2 {
        color: #fff;
      }
    }
    .info-box {
      .smalllist-wrapper-row-icon {
        font-size: 16px;
      }
    }
  }
}

.tenant-changes-history {
  padding: 20px;

  &-header {
    padding: 10px;

    &-row {
      font-weight: bold;
    }
  }

  &-list {
    &-entry {
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 10px;

      a {
        color: rgb(88, 121, 153) !important;
        text-decoration: underline;
      }

      .ant-tag {
        background-color: #F0F4F6;
        border-color: #F0F4F6;
        font-weight: bold;
      }

      &-changes {

        &-entry {
          margin-bottom: 5px;

          

          &-previous {
            .ant-tag {
              opacity: 0.6;
            }

            i {
              margin-right: 10px;
              color: #F0F4F6;
            }
          }
        }
      }
    }
  }
}
