@media print {
  .ant-layout-sider {
    display: none;
  }
  .ant-layout-header {
    display: none;
  }

  .premises-show-slider {
    display: none;
  }

  .ant-page-header-heading {
    display: none;
  }

  .investments-show-right-actions {
    display: none;
  }

  .left .buttons {
    display: none;
  }
}