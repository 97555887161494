.balance-box-red {
  background-color: #ec434c;
}

.balance-box-green {
  background-color: #15d55e;
}

.balance-box {
  color: rgb(255, 255, 255);
  padding: 15px 20px 20px 30px;
  margin-top: 20px;
  display: flex;
  border: 1px solid #ececf7;
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;

  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    .value-wrapper {
      font-size: 32px;
      font-weight: 700;
      margin-top: 25px;
    }

    .title-wrapper {
      font-size: 18px;
      line-height: 32px;
    }
  }

  .buttons-wrapper {
    position: relative;

    .history-button {
      width: 130px;
      height: 25px;
      border-radius: 10px;
      color: #3b4c60;
      font-size: 12px;
      font-weight: 700;
      background-color: #fff;
      line-height: 15px;
      text-align: center;
      padding: 5px 10px 5px 10px;
      border: 0;

      &:hover {
        background-color: #eee;
      }
    }

    .bottom-button-wrapper {
      position: absolute;
      bottom: 0;
      right: 0;

      .balance-button {
        width: 160px;
        height: 32px;
        font-size: 14px;
        font-weight: 700;
        background-color: #252d38;
        border: 0;
        color: #fff;
        padding: 6px 10px 6px 10px;

        &:hover {
          background-color: #363e49;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .balance-box {
    display: block;

    .buttons-wrapper {
      position: relative;

      .history-button {
        margin-top: 20px;
        width: 100% !important;
      }

      .bottom-button-wrapper {
        position: initial !important;

        .balance-button {
          margin-top: 10px;
          width: 100% !important;
        }
      }
    }
  }
}
