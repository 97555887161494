.ant-form-item {
  margin-bottom: 10px;
  box-shadow: none !important;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-select-disabled + label {
    background-color: #fff !important;
    color: #c0d6e5 !important;
  }

  .ant-select-disabled {
    .ant-select-selector {
      background-color: #fff !important;
    }
  }

  .ant-input,
  .ant-picker,
  .ant-select-selector {
    padding: 12px 12px 8px 11px !important;

    .ant-picker-input input {
      height: 26px;
    }
  }

  .investment-stats-datepicker {
    padding: 0 10px !important;

    input {
      margin-top: 2px;
    }
  }

  .ant-select-selection-item {
    line-height: 1.5715 !important;
  }

  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-input-number,
  .ant-select,
  .ant-input-number-input,
  .ant-input-number-input,
  .ant-input {
    border-color: #c0d6e5;

    & + label {
      color: #c0d6e5;
    }

    .ant-select-selector {
      border-color: #c0d6e5 !important;
      box-shadow: none !important;

      .ant-select-selection-overflow {
        margin-top: 9px;
        margin-bottom: 5px;

        .ant-select-selection-overflow-item {
          .ant-select-selection-item {
            background-color: #f0f4f6;
            height: 28px;
            border-radius: 6px;

            .ant-select-selection-item-content {
              color: #587999;
              padding-top: 3px;
            }

            .ant-select-selection-item-remove {
              padding-left: 3px;
              padding-top: 6px;
              .anticon.anticon-close {
                svg {
                  color: #587999;
                  width: 1.4em;
                  height: 1.4em;
                }
              }
            }
          }
        }
      }
    }

    &:hover {
      border-color: #8fb3d1;

      .ant-select-selector {
        border-color: #8fb3d1;
      }
      z-index: 0;
    }

    &:focus,
    &-focused {
      border-color: #587999;
      box-shadow: none !important;
      z-index: 0;
      .ant-select-selector {
        border-color: #587999 !important;
        box-shadow: none !important;
      }
    }

    &:focus + label,
    &-focused + label {
      color: #587999;
    }

    &-disabled {
      background-color: #fff;
      color: #c0d6e5;
    }
  }

  label,
  .ant-input-group-addon {
    color: #c0d6e5;
  }

  &-focused label,
  &-focused .ant-input-group-addon {
    color: #587999;
  }

  &-has-error .ant-input,
  &-has-error .ant-select,
  &-has-error .ant-select:hover,
  &-has-error .ant-select:focus,
  &-has-error .ant-picker,
  &-has-error .ant-picker:hover,
  &-has-error .ant-picker:focus,
  &-has-error .ant-input-number,
  &-has-error .ant-input-number:hover,
  &-has-error .ant-input-number:focus,
  &-has-error .ant-input-affix-wrapper,
  &-has-error .ant-input:hover,
  &-has-error .ant-input-affix-wrapper:hover,
  &-has-error .ant-input:focus,
  &-has-error .ant-input-affix-wrapper:focus {
    border-color: #ff4d4f !important;

    & + label {
      color: #ff4d4f !important;
    }
  }

  &-has-error label {
    color: #ff4d4f !important;
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    background-color: #f0f4f6;
    height: 25px;
    line-height: 25px;
  }
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0px 10px 0px 11px !important;
}

.ant-input-affix-wrapper {
  padding: 0px 12px 0px 0px !important;
}

.ant-select-selection-search {
  top: -2px !important;
}

.ant-select-single.ant-select-show-search {
  .ant-select-selection-search {
    top: 6px !important;
  }
  .ant-select-selection-item {
    top: 13px !important;
  }
}

.ant-select-clear .anticon-close-circle {
  margin-top: 0px;
}

.ant-form-item-explain-error {
  margin-bottom: 20px;
}

.ant-form-item-feedback-icon {
  height: 18px;
  padding: 0 !important;
}

.ant-picker-input {
  input {
    border-radius: 0;
  }
}

.ant-input-number {
  border-color: #c0d6e5;
  padding: 12px 12px 8px 11px !important;

  .ant-input-number-input {
    height: 26px;
    padding: 0;
    border-radius: 0;
  }

  & + label {
    color: #c0d6e5;
  }

  .ant-select-selector {
    border-color: #c0d6e5 !important;
    box-shadow: none !important;
  }

  &:hover {
    border-color: #8fb3d1;

    .ant-select-selector {
      border-color: #8fb3d1;
    }
    z-index: 0;
  }

  &:focus,
  &-focused {
    border-color: #587999;
    box-shadow: none !important;
    z-index: 0;
    .ant-select-selector {
      border-color: #587999 !important;
      box-shadow: none !important;
    }
  }

  &:focus + label,
  &-focused + label {
    color: #587999;
  }

  &-disabled {
    background-color: #fff;
    color: rgb(192, 214, 229);

    & + label {
      color: rgb(192, 214, 229);
    }
  }

  &-has-error .ant-input-number:hover,
  &-has-error .ant-input-number:focus {
    border-color: #ff4d4f !important;

    label,
    .ant-input-group-addon {
      color: #ff4d4f !important;
    }
  }
}

.ant-input-group {
  border: 1px solid #c0d6e5;
  border-radius: 10px;
  height: 48px;

  input {
    height: 46px;
  }

  .ant-input-group-addon {
    border: 0;
    background-color: #fff;
    // z-index: 99;
    z-index: 1;
  }

  .ant-input {
    border: 0;
  }
}

.ant-input-group:focus-within {
  border-color: #587999;

  .ant-input-group-addon {
    color: #587999;
  }
}

.ant-form-item-control-input-content:focus-within {
  label {
    color: #587999;
  }
}

.ant-form-item-has-error {
  .ant-input-group-addon {
    color: #ff4d4f !important;
  }

  .ant-input-group {
    border-color: #ff4d4f !important;
  }
}

.upload-btn-wrapper {
  color: #b1cade;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;

  .upload-img-icon {
    color: #c0d6e5;
    font-size: 20px;
  }
}

.ant-upload-select {
  width: 100%;
}

.subscription-input-number {
  z-index: 0;
}

.ant-input-number-group-wrapper
  .ant-input-number-wrapper
  .ant-input-number-focused
  + label {
  color: #587999;
}

.ant-select-selection-item-remove {
  padding-top: 5px;
}

.ant-input-number-group-wrapper {
  & + label {
    color: #c0d6e5;
  }

  .ant-input-number-wrapper.ant-input-number-group {
    .ant-input-number {
      border-right: 0;
      z-index: 0;
    }

    .ant-input-number-group-addon {
      background-color: #fff;
      border-left: 0;
      color: #c0d6e5;
      border-color: #c0d6e5;
    }
  }
}

.ant-input-number-group-wrapper:focus-within {
  & + label {
    color: #587999;
  }

  .ant-input-number-wrapper.ant-input-number-group {
    .ant-input-number-group-addon {
      color: #587999;
      border-color: #587999;
    }
  }
}

.ant-input-number-group-wrapper:hover {
  .ant-input-number-wrapper.ant-input-number-group {
    .ant-input-number-group-addon {
      border-color: #8fb3d1;
    }
  }
}

.choosenInvestmentsLabel {
  width: 223px !important;
}

.ant-select-item-option-selected {
  background-color: #fff !important;
}

.permissions .ant-col.ant-form-item-label label {
  color: #3b4c60;
  font-weight: 400;
}

.ant-select-item.ant-select-item-option {
  color: #b1cade;
  background-color: #fff;

  font-size: 14px;

  &:hover {
    background-color: #f0f4f6;
  }
}

.ant-select-item-option-selected {
  background-color: #f0f4f6 !important;
  font-weight: 400 !important;
  color: #587999 !important;
}

.ant-picker-panel-container {
  .ant-picker-panel {
    .ant-picker-footer {
      .ant-picker-ranges {
        .ant-picker-ok {
          .ant-btn.ant-btn-primary.ant-btn-sm {
            min-width: 60px;
            min-height: 30px;
          }
        }
      }
    }
  }
}
