.demo-mode .menu-header {
  .border-wrapper {
    background-color: #587999 !important;
  }
  .image-wrapper {
    background-image: url('../../static/images/demo_avatar.png');
  }
}

.login-form-wrapper {
  .demo-info {
    margin-bottom: 20px;
    font-size: 12px;
    color: #8FB3D1;

    span {
      font-weight: 600;
    }
  }
}