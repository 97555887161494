.tiles-wrapper {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fit, 180px);

  .tile-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 177px;
    height: 177px;
    min-width: 177px;
    min-height: 177px;
    background-color: #ececf7;

    border-radius: 25px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .tile-content {
        .tile-icon {
          text-align: center;
          color: @mainBlueColor;
        }

        .tile-name {
          color: @mainBlueColor;
          font-family: Overpass;
          font-size: 18px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: center;
        }
      }
    }

    &:hover {
      background-color: #d5d5f3;
    }
  }
}

.options-investment-name {
  color: #3b4c60;
  font-family: Overpass;
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 20px;
}

.options-investment-address {
  color: #b1cade;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .tiles-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(177px, 1fr));
  }
}

@media only screen and (max-width: 576px) {
  .tiles-wrapper {
    grid-gap: 10px;

    .tile-wrapper {
      width: 100%;
    }
  }
}
