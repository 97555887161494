.tags-filter {
  margin-bottom: 10px;
  .ant-tag {
    color: @mainBlueColor;
    background-color: #ECECF7;
    border-color: #ECECF7;
    font-weight: bold;
    padding: 4px 15px;
    cursor: pointer;

    &:focus,
    &:hover,
    &:active,
    &.tag-active {
      background-color: #A7A7FA;
      border-color: #A7A7FA;
      color: @mainBlueColor;
    }
  }
}