.dashboard {
  &-elem {
    background-color: #f0f4f6;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;

    .header {
      h2 {
        color: #3b4c60;
        font-size: 20px;
        margin-bottom: 20px;

        .font-icon {
          font-size: 16px;
          margin-right: 7px;
          vertical-align: text-top;
        }

        .dashboard-link {
          color: inherit !important;
        }
      }

      .actions {
        text-align: right;

        .ant-btn {
          color: #587999;
          background-color: #e3edf4;
          font-size: 12px;
          border: none;
          margin-left: 5px;

          .font-icon {
            margin-left: 5px;
          }
        }
      }
    }

    .content {
      .custom-agreements-elem {
        border-radius: 20px;
        background-color: #d5d5f3;
        font-size: 12px;
        margin-bottom: 10px;

        .label {
          color: #a7a7fa;
        }

        .value {
          font-weight: bold;
        }

        &-top {
          margin-bottom: 20px;
          .value {
            font-size: 20px;
          }
        }

        &-left {
          height: 100%;
          padding: 20px;
          border-radius: 20px;
          background-color: @mainBlueColor;
          overflow: hidden;

          .label {
            color: rgba(255, 255, 255, 0.5);
          }

          .value {
            color: #fff;
          }
        }

        &-right {
          padding: 20px;

          .value {
            color: #5353f4;
          }
        }

        .showMoreButton {
          text-align: center;
          padding: 20px;
          font-size: 20px;

          .font-icon {
            cursor: pointer;

            &:before {
              transform: rotate(90deg);
              color: #5353f4;
            }

            &.rotate:before {
              transform: rotate(270deg);
              color: #a7a7fa;
            }
          }
        }

        &-bottom-attachments {
          margin-top: 20px;

          h2 {
            font-weight: bold;
            font-size: 24px;
            color: @mainBlueColor;
          }

          .attachment {
            background-color: transparent;
            border: 1px solid #a7a7fa;
            line-height: 30px;
            width: auto;
            margin: 10px;
            color: @mainBlueColor;
            font-weight: bold;

            &-icon .font-icon {
              color: @mainBlueColor;
              font-size: 15px;
            }

            &-actions .ant-btn {
              color: @mainBlueColor;
            }
          }
        }
      }

      &-elem {
        color: #fff;
        font-size: 12px;
        background-color: transparent;
        border-radius: 20px;
        margin-bottom: 20px;

        &-top {
          background-color: transparent;
          padding: 20px;

          border-radius: 20px;
          &-higher {
            font-size: 34px;
            font-weight: bold;
          }
        }

        &-bottom {
          padding: 20px;
          &-lower {
            font-weight: bold;
          }
        }

        &.dashboard-elem-red {
          background-color: #ea7e84;
          .content-elem-top {
            background-color: #ed565e;
          }
          .content-elem-bottom {
            color: #b1494e;
          }
        }

        &.dashboard-elem-blue {
          background-color: #b1cade;
          .content-elem-top {
            background-color: #8fb3d1;
          }
          .content-elem-bottom {
            color: #587999;
          }
        }

        &.dashboard-elem-darkblue {
          background-color: #587999;
          .content-elem-top {
            background-color: #3b4c60;
          }
          .content-elem-bottom {
            color: #3b4c60;
          }
        }

        &.dashboard-elem-grey {
          color: #587999;
          background-color: #e3edf4;
        }

        &.dashboard-elem-navy {
          background-color: #7777f6;
          .content-elem-top {
            background-color: @mainBlueColor;
          }
          .content-elem-bottom {
            color: #5151ba;
          }
        }

        &.dashboard-elem-green {
          background-color: #56e089;
          .content-elem-top {
            background-color: @mainGreenColor;
          }
          .content-elem-bottom {
            color: #14ad4e;
          }
        }

        &.dashboard-elem-empty {
          border: 2px solid #e3edf4;
          .content-elem-top {
            color: #587999;
            border: 2px solid #c0d6e5;
          }
          .content-elem-bottom {
            color: #b1cade;
          }
        }

        &.hide {
          border: 2px solid #e3edf4;
          background-color: transparent;
          opacity: 0.5;
          .content-elem-top {
            color: #587999;
            border: none;
            background-color: transparent;
          }
          .content-elem-bottom {
            visibility: hidden;
          }
        }

        &.dashboard-link {
          transition: all 0.2s ease-out;

          &:hover {
            opacity: 0.9;
          }
        }
        &.custom-premises-dashboard-stats {
          background-color: #587999;
          .content-elem-top {
            background-color: #587999;
          }
          .content-elem-bottom {
            color: #c0d6e5;
          }
          &.dashboard-elem-darkblue {
            background-color: #3b4c60;
            .content-elem-top {
              background-color: #3b4c60;
            }
          }

          .custom-premises-dashboard-stats-premises-top {
            font-size: 24px;
            font-weight: bold;
          }
          .custom-premises-dashboard-stats-premises-bottom {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      &-elem-full {
        padding: 20px;
        border-radius: 20px;
        margin-bottom: 10px;

        &-left {
          &-bottom {
            font-weight: bold;
            font-size: 24px;
          }
        }

        &-middle {
          &-bottom {
            font-weight: bold;
            font-size: 24px;
          }
        }

        &-right {
          &-bottom {
            font-size: 12px;
          }
        }

        &-href {
          margin-top: 18px;

          .font-icon:before {
            transform: rotate(180deg);
          }
        }

        &.dashboard-elem-full-grey {
          background-color: #ececf7;
          color: #5353f4;

          .content-elem-full-middle,
          .content-elem-full-left,
          .content-elem-full-right {
            &-top {
              color: #a7a7fa;
            }
          }

          .content-elem-full-href a {
            color: #a7a7fa !important;
          }
        }

        &.dashboard-elem-full-black {
          background-color: #3b4c60;
          color: #fff;

          .content-elem-full-middle,
          .content-elem-full-left,
          .content-elem-full-right {
            &-top {
              color: #587999;
            }
          }

          .content-elem-full-href a {
            color: #587999 !important;
          }
        }

        &.dashboard-elem-full-navy {
          background-color: @mainBlueColor;
          color: #fff;

          .content-elem-full-middle,
          .content-elem-full-left,
          .content-elem-full-right {
            &-top {
              color: #a7a7fa;
            }
          }

          .content-elem-full-href a {
            color: #a7a7fa !important;
          }
        }
      }
    }
  }
}

.dashboard-elem {
  .dashboard-resolution-info {
    padding-right: 40px;

    .dashboard-resolution-info {
      .dashboard-resolution-info-col {
        text-align: right;
      }
    }
  }

  .resolution-wrapper {
    flex: 1;
    display: flex;
    background-color: #f0f4f6;
    border: 2px solid #b9c9da;
    border-radius: 10px;
    padding: 20px 0 20px 30px;

    .resolution-result {
      padding: 8px 16px;
      background-color: #fff;
      font-size: 14px;
      font-weight: 700;
      border-radius: 12px;
    }

    .resolution-redirect-btn {
      width: 30px;
      display: flex;
      align-items: center;
    }

    .resolution-show-btn {
      background-color: #ec434c;
      border: 0;
      min-height: 32px;
      min-width: 120px;
    }
  }
}

.custom-parking-row-info {
  justify-content: end;
}

@media only screen and (max-width: 575px) {
  .dashboard-elem {
    .header {
      .dashborad-header-action-btns-wrapper {
        text-align: left;
      }
    }

    .resolution-wrapper {
      .dashboard-resolution-info {
        padding: 10px 5px 10px 0px;
      }

      .dashboard-resolution-info-col {
        text-align: left;
      }
    }
  }

  .custom-parking-row-info {
    justify-content: start;
  }
}

@media only screen and (max-width: 450px) {
  .dashboard-elem {
    padding-left: 10px;
    padding-right: 10px;

    .header {
      .title {
        padding-left: 10px;
      }
    }

    .resolution-wrapper {
      padding: 10px 0 0 10px;

      .resolution-result {
        padding: 8px;
      }

      .resolution-redirect-btn {
        width: 20px;
      }
    }
  }
}
