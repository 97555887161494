.firstLogin {
  &-welcome {
    &-modal-wrapper {
      overflow: hidden;
      height: 550px;
    }
    &-welcomeAdmin-img {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 20px 20px 0px 0px;
    }
    &-logo-img {
      position: absolute;
      width: 170px;
      left: 30px;
      top: 30px;
    }
    &-content {
      position: absolute;
      top: 290px;
      width: calc(100% - 48px);

      &-header {
        // position: absolute;
        // top: 290px;

        font-family: 'Overpass';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;

        color: #3b4c60;
      }
      &-message {
        // position: absolute;
        // width: 350px;
        // top: 330px;

        font-family: 'Overpass';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        color: #3b4c60;
        a {
          text-decoration: underline;
          color: #3b4c60;
        }
      }
    }
    &-button-wrapper {
      position: absolute;
      top: 520px;
      display: flex;
      left: 115px;
    }
  }

  &-form {
    &-card {
      border: 1px solid #5353f4;
      border-radius: 20px;
      margin-bottom: 30px !important;
    }
    &-button-send-password {
      width: 100% !important;
      height: 48px;
      background-color: #5353f4;
      border: 0;

      span {
        margin-right: 15px;
        color: #fff;
      }
    }
    &-button-send-password:hover,
    &-button-send-password:active,
    &-button-send-password:focus {
      background-color: #6464f5;
      border: 0;
    }
  }
}

@media only screen and(max-width: 576px) {
  .firstLogin-form-card {
    margin-left: 0;
    margin-right: 0;

    .ant-card-body {
      padding: 0;
    }
  }
}

.firstLogin-rodo-card {
  margin-bottom: 30px;
  max-height: 300px;
  overflow: auto;

  /* Works on Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: #3b4c60 transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;

    margin: 10px 0; /* Odsunięcie od góry i dołu o 10px */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3b4c60;
    border-radius: 20px;
    border: 3px solid transparent;
  }

  .ant-card-body {
    padding: 20px 20px 0px 20px;
  }
}

.firstLogin-rodo-accept {
  .ant-row.ant-form-item-row {
    justify-content: flex-end;

    .ant-col.ant-form-item-label {
      flex: none;

      label {
        &::after {
          content: none;
          margin: 0;
        }

        div::after {
          content: ':';
          position: relative;
          top: -0.5px;
          margin: 0 8px 0 2px;
        }
      }
    }

    .ant-col.ant-form-item-control {
      flex: none;
    }
  }
}

@media only screen and (min-width: 500px) {
  .firstLogin-welcome-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .firstLogin-welcome-content {
    top: 260px;
  }
}

@media only screen and (max-width: 340px) {
  .firstLogin-welcome-content {
    top: 240px;
  }
}
