.themes {
  padding: 50px;
  .theme {
    margin-bottom: 20px;

    .checkbox {
      margin-bottom: 10px;
    }

    .colors {
      border: 2px solid #c0d6e5;
      border-radius: 10px;
      max-width: 246px;
      padding: 10px;

      &-box {
        display: block;
        width: 25x;
        height: 25px;
        border-radius: 20px;

        @media only screen and (min-width: 576px) {
          width: 64px;
          height: 64px;
        }
      }
    }

    &-default {
      .primary {
        background-color: #5353f4;
      }
      .secondary {
        background-color: #15d55e;
      }
      .text {
        background-color: #e3edf4;
      }
    }
    &-sky {
      .primary {
        background-color: #3b4c60;
      }
      .secondary {
        background-color: #499ae9;
      }
      .text {
        background-color: #e3edf4;
      }
    }
    &-brick {
      .primary {
        background-color: #3b4c60;
      }
      .secondary {
        background-color: #d6897c;
      }
      .text {
        background-color: #e3edf4;
      }
    }
    &-mech {
      .primary {
        background-color: #3b4c60;
      }
      .secondary {
        background-color: #4ece89;
      }
      .text {
        background-color: #e3edf4;
      }
    }
  }
}

.theme-mixin(@mainColor, @secondColor) {
  .ant-picker-dropdown,
  .ant-popover,
  .ant-modal-root,
  .logged-layout-container {
    ::selection {
      background-color: @mainColor;
    }

    .site-layout-header .trigger {
      color: @mainColor;
    }

    .ant-page-header-back,
    .tile-wrapper,
    .investments-show-right-actions-action {
      background-color: lighten(@mainColor, 30%);

      &:hover {
        background-color: lighten(@mainColor, 25%);
      }
    }

    .show-layout .right .ant-card.access .ant-card-body h2 {
      color: @secondColor;
    }

    .ant-spin-dot-item {
      background-color: @mainColor;
    }

    .ant-layout-sider .menu-header .logo .border-wrapper {
      background-color: @mainColor;
    }

    .ant-btn-primary {
      background-color: @mainColor;
      border-color: @mainColor;
    }

    .button-secondary {
      color: @mainColor;
      background-color: lighten(@mainColor, 30%);
      border-color: lighten(@mainColor, 30%);

      &:hover,
      &:active,
      &:focus {
        background-color: lighten(@mainColor, 25%);
        border-color: lighten(@mainColor, 25%);
      }
    }

    .show-layout .left .buttons .write button {
      background-color: @secondColor;
      border-color: @secondColor;
    }

    .investments-show-right-actions-action {
      color: @secondColor !important;
    }

    .show-layout .right .ant-card-head h2 {
      color: @secondColor;
    }

    .show-layout .right .ant-card .ant-card-body .title {
      color: @secondColor;
    }

    .ant-pagination-item-active a {
      color: @mainColor;
    }

    .ant-pagination-item-active {
      border-color: @mainColor;
    }

    .form-create .create-form-password-card .create-form-button-send-password {
      background-color: @secondColor;

      &:hover {
        border-color: @secondColor;
      }
    }

    .form-create .create-form-password-card {
      border-color: @secondColor;
    }

    .ant-switch-checked {
      background-color: @mainColor;
    }

    .premises-show-box.price-box,
    .premises-show-box.costs-box {
      background-color: @mainColor;

      &:before {
        background-color: darken(@mainColor, 5%);
      }

      .content .title .ant-btn {
        &:hover,
        &:active,
        &:focus {
          color: darken(#fff, 20%);
          border-color: darken(#fff, 20%);
        }
      }
    }

    .premises-stats-SALE .premises-stats-elem {
      background-color: @mainColor;

      &:before {
        background-color: darken(@mainColor, 5%);
      }
    }
    .premises-show-box h2 {
      color: @secondColor;
    }

    .premises-show-box.agreements-box {
      h2 {
        color: #fff;
      }
      background-color: @secondColor;
      &:before {
        background-color: darken(@secondColor, 5%);
      }
    }

    .premises-show-box.agreements-box .single-agreement .ant-table-content {
      background-color: @secondColor;
      border-color: none;
    }

    .ant-page-header-heading-title span.header-subtitle {
      color: @secondColor;
    }

    .ant-page-header-back .ant-page-header-back-button i,
    .tile-content .tile-icon i,
    .tile-content .tile-name {
      color: @secondColor !important;
    }

    .header-package-button-actual {
      color: @secondColor !important;
    }

    .faults-show .description-box {
      h2 {
        color: #fff;
      }
      background-color: @secondColor;
    }

    .faults-show .finances-box-header {
      color: @secondColor;

      h2 {
        color: @secondColor;
      }

      .font-icon {
        color: @secondColor;
      }
    }

    .faults-show
      .finances-box
      .content
      .finances-box-content-header
      .finances-box-content-header-total {
      color: @secondColor;
    }

    .tenantChanges-show .description-box {
      h2 {
        color: #fff;
      }
      background-color: @secondColor;
    }

    .dashboard-elem .content-elem.dashboard-elem-green {
      background-color: lighten(@mainColor, 5%);
    }

    .dashboard-elem .content-elem.dashboard-elem-green .content-elem-top {
      background-color: @mainColor;
    }

    .dashboard-elem .content-elem.dashboard-elem-green .content-elem-bottom {
      color: darken(@mainColor, 5%);
    }

    .dashboard-elem .content-elem.dashboard-elem-navy {
      background-color: lighten(@secondColor, 5%);
    }

    .dashboard-elem .content-elem.dashboard-elem-navy .content-elem-top {
      background-color: @secondColor;
    }

    .dashboard-elem .content-elem.dashboard-elem-navy .content-elem-bottom {
      color: darken(@secondColor, 5%);
    }

    .premises-stats-STATS .premises-stats-elem-value {
      color: @secondColor;
    }

    .premises-stats-RENT .premises-stats-elem {
      background-color: @secondColor;

      &:before {
        background-color: darken(@secondColor, 5%);
      }
    }

    .button-secondary.active {
      background-color: @mainColor;
      border-color: @mainColor;
      color: #fff;
    }

    .premises-show-box.room-box .room-box-costs .value {
      color: @secondColor;
    }

    .premises-show-box.room-box .rooms-list-room-costs-rent,
    .premises-show-box.room-box .rooms-list-room-costs-charges {
      color: @secondColor;
    }

    .form-create .ant-tabs-tab:hover,
    .form-create .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @secondColor;
    }

    .premises-show-box.brokers-box .ant-table-tbody td:last-child {
      color: @secondColor;
    }

    .premises-broker-phone {
      color: lighten(@secondColor, 50%) !important;
    }
    .premises-show-box.agreements-box .single-agreement .label,
    .premises-show-box.info-box .content .label {
      color: lighten(@secondColor, 50%);
    }

    .premises-show-box.agreements-box .single-agreement {
      border-color: lighten(@secondColor, 10%);
    }

    .premises-show-box.agreements-box
      .single-agreement
      .ant-table-content
      .ant-table-tbody
      td,
    .premises-show-box.agreements-box
      .single-agreement
      .ant-table-content
      .ant-table-tbody
      tr:hover
      td {
      border-color: lighten(@secondColor, 20%);
      background-color: lighten(@secondColor, 20%);
    }

    .faults-show .finances-box .content .finances-box-content-content-label {
      color: lighten(@secondColor, 50%);
    }

    .premises-stats-STATS .premises-stats-elem {
      background-color: lighten(@mainColor, 30%);

      &:before {
        background-color: darken(lighten(@mainColor, 30%), 5%);
      }
    }
    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border-color: @mainColor;
    }
    .ant-picker-header-view button:hover {
      color: @mainColor;
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: @mainColor;
    }
    .ant-picker-today-btn {
      color: @mainColor;
    }

    .dashboard-elem .content .custom-agreements-elem-left {
      background-color: @mainColor;
    }

    .dashboard-elem .content .custom-agreements-elem-right .value {
      color: @mainColor;
    }

    .dashboard-elem .content .custom-agreements-elem-right .label {
      color: fade(@mainColor, 50%);
    }

    .dashboard-elem .content .custom-agreements-elem {
      background-color: lighten(@mainColor, 30%);

      &-bottom-attachments {
        h2 {
          color: @mainColor;
        }

        .attachment {
          border-color: lighten(@mainColor, 20%);
          color: @mainColor;

          &-icon .font-icon {
            color: @mainColor;
          }
          &-actions .ant-btn {
            color: @mainColor;
          }
        }
      }
    }

    .tpay-settings h2 {
      color: @mainColor;
    }
    .premises-show-box.brokers-box .ant-table-tbody td {
      background-color: lighten(@mainColor, 30%);
      border-color: lighten(@mainColor, 30%);
    }

    .dashboard-elem
      .content
      .custom-agreements-elem
      .showMoreButton
      .font-icon:before {
      color: @mainColor;
    }

    .show-all-members-btn {
      background-color: @mainColor !important;
      color: #fff !important;
    }
  }

  .counters-edit-btn {
    background-color: @secondColor !important;
    border-color: @secondColor !important;

    &:hover {
      background-color: lighten(@secondColor, 5%) !important;
      border-color: lighten(@secondColor, 5%) !important;
    }
  }

  .secondary-bg {
    background-color: @secondColor;

    .shares-tooltip-header {
      color: #ddd !important;
    }
  }

  .resolution-details-btn {
    background-color: @mainColor;
  }

  .ant-modal-header {
    .ant-modal-title {
      .modal-title {
        color: @mainColor !important;
      }
    }
  }
}

body.theme-sky {
  .theme-mixin(#499ae9, #252d38);
  .text-secondary {
    color: #499ae9;
  }
}
body.theme-brick {
  .theme-mixin(#D6897C, #3b4c60);
  .text-secondary {
    color: #d6897c;
  }
}

body.theme-mech {
  .theme-mixin(#4ece89, #3b4c60);
  .text-secondary {
    color: #4ece89;
  }

  .ant-picker-dropdown,
  .ant-popover,
  .ant-modal-root,
  .logged-layout-container {
    .button-secondary {
      background-color: lighten(#4ece89, 35%);
      border-color: lighten(#4ece89, 35%);
    }

    .dashboard-elem .content .custom-agreements-elem .label {
      color: rgba(255, 255, 255, 0.7);
    }
    .dashboard-elem .content .custom-agreements-elem-right .label {
      color: rgba(78, 206, 137, 0.7);
    }

    .dashboard-elem .content-elem.dashboard-elem-green .content-elem-bottom {
      color: #fff;
    }
  }
}
