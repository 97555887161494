.text-secondary {
  color: #5353f4;
}

.resolution-details-btn {
  background-color: #5353f4;
  color: #fff;
}

.start-resolution-modal {
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: space-between;

    .ant-btn.ant-btn-primary.ant-btn-lg {
      span {
        margin-top: 4px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: #3b4c60;
    }

    .ant-modal-confirm-content {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #b1cade;
    }
  }
}

.end-resolution-modal {
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: space-between;

    .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-dangerous {
      span {
        margin-top: 4px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .ant-modal-confirm-body {
    .ant-modal-confirm-title {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: #3b4c60;
    }

    .ant-modal-confirm-content {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #b1cade;
    }
  }
}

.resolutions-piechart-pro-label {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resolutions-piechart-con-label {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resolutions-piechart-abstain-label {
  margin-top: 10px;
}

.react-draft-wysiwyg-resolution-details {
  img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .resolutions-piechart-content-wrapper {
    flex-direction: column;

    .resolutions-piechart-wrapper {
      order: 1;
    }

    .resolutions-piechart-pro-label {
      margin-top: 10px;
      order: 2;
    }

    .resolutions-piechart-con-label {
      order: 3;
    }
  }

  .resolutions-piechart-abstain-label {
    margin-bottom: 10px;
    margin-top: 0;
  }
}
