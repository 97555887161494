@media only screen and (max-width: 576px) {
  .datagrid-wrapper .datagrid-actions-wrapper,
  .ant-page-header-heading-extra {
    .ant-btn {
      padding: 10px;
      line-height: 1;
      width: 36px !important;
      height: 36px;
      min-width: 36px !important;
      min-height: 36px !important;

      & > span {
        display: none;
      }
      & > i {
        padding-left: 0;
      }
    }
  }

  .ant-page-header-heading-extra {
    .ant-btn {
      border-radius: 5px;
      height: 39px;
    }
  }

  .datagrid-wrapper .datagrid-mass-actions-wrapper {
    .ant-btn {
      min-height: 36px !important;
      height: 36px !important;
    }
  }

  .investment-show-card {
    border: 0;
  }

  .show-layout .left.investments-show-left {
    max-width: none;
    width: 100%;
  }

  .premises-stats-elem-value {
    font-size: 25px !important;
  }

  .form-create .ant-tabs-tab:hover,
  .form-create .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: red;
  }

  #czater-contener {
    display: none !important;
  }

  .create-form-password-card {
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px;
  }

  .form-create,
  .show-layout {
    .ant-page-header-back .ant-page-header-back-button {
      padding: 10px !important;
    }
  }

  .ant-page-header-heading-left {
    width: calc(100vw - 24px);
  }

  .page-header-extra-2 .ant-page-header-heading-left {
    width: calc(100% - 100px);
  }

  .gallery-wrapper-add {
    max-width: initial;
    max-height: initial;
  }

  .premises-show-box.costs-box .content .value {
    font-size: 30px;
  }

  .investments-list-item {
    max-width: none;
    aspect-ratio: 1/1;

    .investments-list-item-logo {
      height: calc(100% - 85px);
    }
  }

  .investments-list-item-drag {
    display: none !important;
  }

  .resolution-details-datagrid-wrapper {
    .datagrid-wrapper {
      .ant-row.ant-row-space-between {
        h1 {
          width: calc(100% - 50px) !important;

          .ant-page-header.ant-page-header-compact {
            .ant-page-header-heading {
              .ant-page-header-heading-left {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }

  .ant-col.ant-form-item-label {
    display: inline-block !important;
    margin-right: 10px;
    flex: initial !important;
  }

  .ant-col.ant-form-item-control {
    flex: 1 1 !important;
  }

  .settlement-template-wrapper {
    padding: 20px !important;
  }

  .notifications-popover {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

@media only screen and (max-width: 1199px) {
  .form-create .ant-tabs-nav-list .ant-tabs-tab:first-child {
    margin-left: 20px !important;
  }
  .form-create .ant-tabs-nav-list .ant-tabs-tab:nth-last-child(2) {
    margin-right: 20px !important;
  }
}

@media only screen and (min-width: 576px) {
  .ant-tabs-tab-active {
    background-color: #f0f4f6;
  }

  .all-investments-stats-button .font-icon {
    padding-left: 5px;
  }

  .non-mobile-align-right {
    text-align: right;
  }

  .premises-stats-elem:before {
    width: 70%;
  }
}

@media only screen and (min-width: 768px) {
  .premises-stats-elem:before {
    width: 75%;
  }
}

@media only screen and (min-width: 992px) {
  .premises-stats-elem:before {
    width: 80%;
  }

  .ant-col-lg-offset-4 {
    margin-top: 8.333333333%;
    margin-bottom: 8.333333333%;
  }
}

@media only screen and (min-width: 1200px) {
  .premises-stats-elem:before {
    width: 85%;
  }
  .form-create .ant-tabs-nav-list {
    width: 274px;
    min-height: 500px;
  }
}

@media only screen and (min-width: 1600px) {
  .premises-stats-elem:before {
    width: 90%;
  }
}
