.smalllist-wrapper {
  .ant-table-placeholder .ant-table-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .smalllist-link {
    color: @mainBlackColor !important;

    &:hover {
      text-decoration: underline;
    }
  }
  .smalllist-inherit-link {
    color: inherit !important;

    &:hover {
      text-decoration: underline;
    }
  }

  .ant-table {
    font-size: 12px;
    overflow-x: auto;
    background-color: unset;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  .ant-table-thead {
    th {
      white-space: nowrap;
      background-color: #fff;
      color: #b1cade;
      border-bottom: none;
    }
  }
  .ant-table-tbody {
    td {
      background-color: #f0f4f6;
      border-bottom: none;

      &:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        padding-left: 30px;
      }

      &:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }

    tr.ant-table-row:hover > td,
    tr.ant-table-row-selected > td {
      background-color: #f0f4f6;
    }

    tr.ant-table-placeholder > td {
      background: #fff;
    }
  }
  .ant-table-summary {
    td {
      border-bottom: none;
    }
  }
  &-row {
    &-action {
      &.ant-btn {
        background: transparent !important;
        color: #c0d5e4;

        &:hover,
        &:active {
          color: rgba(0, 0, 0, 0.85);
        }
        &::after {
          box-shadow: none;
          border: none;
        }
      }

      .icon-more-vertical::before {
        transform: rotate(90deg);
      }
    }

    &-icon {
      color: #c0d5e4;
      font-size: 25px;
    }
  }
}

.white-table {
  .ant-table-row {
    .ant-table-cell {
      background-color: #fff !important;
      border-top: 1px solid #e3edf4;
      border-bottom: 1px solid #e3edf4;
    }
    .ant-table-cell:first-child {
      border-left: 1px solid #e3edf4;
    }
    .ant-table-cell:last-child {
      border-right: 1px solid #e3edf4;
    }
  }
}

.premise-counters-show {
  .smalllist-wrapper {
    .ant-btn,
    .ant-btn:hover,
    .ant-btn:active {
      color: #3b4c60;
    }

    .ant-table-thead {
      .ant-table-cell {
        color: #3b4c60;
        font-size: 12px;
        font-weight: 700;
      }
      .ant-table-cell:first-child {
        padding-left: 30px;
      }
    }

    .ant-table-tbody {
      .ant-table-row:hover .ant-table-cell {
        background-color: #fff !important;
      }

      .ant-table-cell {
        padding-top: 15px;
        padding-bottom: 10px;

        .icon-arrow-simply-right::before {
          transform: rotate(180deg);
        }
      }
      .ant-table-cell:first-child {
        padding-left: 30px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .ant-table-cell:last-child {
        width: 40px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.show-import-btn {
  width: 100px;
}

.ant-modal.import-preview-modal {
  .ant-modal-header {
    border-radius: 20px;
  }
}

.import-preview-wrapper {
  .ant-table {
    font-size: 12px;
    overflow-x: auto;
    background-color: unset;
    scrollbar-width: thin;
    border: 1px solid #c0d6e5;

    .ant-table-thead {
      .ant-table-cell {
        font-family: Overpass;
        padding: 8px;
        padding-top: 12px;
        font-weight: 700;
        border-color: #c0d6e5;
      }
    }

    .ant-table-tbody {
      .ant-table-row.ant-table-row-level-0.row-red {
        background-color: #fdeced;
      }

      .ant-table-cell {
        font-family: Overpass;
        padding: 4px 8px;
        border-color: #c0d6e5;

        .font-icon.icon-close {
          &::before {
            rotate: 45deg;
          }
        }
      }

      .ant-table-row.ant-table-row-level-0:last-child {
        .ant-table-cell {
          border-bottom: 0;
        }
      }
    }
  }
}
