.ant-message-notice-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 446px;
}

.ant-message .anticon-check-circle,
.ant-message .anticon-close-circle {
  width: 100%;
  margin-top: 20px;
}

.message-title {
  font-family: Overpass;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  margin: 20px;
  color: #3b4c60;
}

.message-annotation {
  font-family: Overpass;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #b1cade;
  margin-bottom: 20px;
}

.message-close-btn-success,
.message-close-btn-success:hover {
  background-color: #e9f5ee;
  color: #15d55e;
  width: 178px;
  height: 48px;
  font-family: Overpass;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  border: 0;
  margin-bottom: 20px;
}

.message-close-btn-success:hover {
  background-color: #d8e4dd;
}

.message-close-btn-error,
.message-close-btn-error:hover {
  background-color: #fdeeef;
  color: #ec434c;
  width: 178px;
  height: 48px;
  font-family: Overpass;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  border: 0;
  margin-bottom: 20px;
}

.message-close-btn-error:hover {
  background-color: #ecddde;
}

.message-copied {
  .ant-message-notice-content {
    width: 300px;
  }
}
