.faults {
  &-edit {
    .attachments,
    .finances {
      .smalllist-wrapper-row-icon {
        font-size: 16px;
      }
    }
  }

  &-show {
    .faults-notification {
      width: 100%;
      margin-bottom: 10px;

      .ant-alert {
        background-color: #f0f4f6;
        border: none;
        padding: 20px;
        border-radius: 20px;

        &-message {
          color: #3b4c60;

          .font-bold {
            font-weight: bold;
          }
        }

        &-action {
          .confirm-fault-button {
            margin-right: 10px;
          }
        }
      }
    }
    .comment-box {
      .content {
        padding: 20px 20px;
      }

      .single-comment {
        border: 1px solid #ececf7;
        padding: 20px;
        margin-bottom: 10px;
        border-radius: 10px;

        &-header {
          margin-bottom: 10px;

          &-left {
            i {
              margin-right: 10px;
            }
          }

          &-right {
            text-align: right;

            @media only screen and (max-width: 576px) {
              text-align: left;
            }
          }
        }
      }
    }
    .description-box {
      .content {
        padding: 20px 35px;
        white-space: pre-wrap;
      }

      background-color: @mainBlueColor;
      color: #fff;

      h2 {
        color: #fff;
      }
    }
    .info-box {
      .smalllist-wrapper-row-icon {
        font-size: 14px;
        vertical-align: text-bottom;
      }
    }

    .finances-box {
      &-header {
        padding: 20px;
        color: @mainBlueColor;

        h2 {
          color: @mainBlueColor;
          font-weight: bold;
          font-size: 18px;
        }

        .font-icon {
          color: @mainBlueColor;
          margin-right: 5px;
        }

        .finances-box-header-total {
          font-size: 28px;
          font-weight: bold;
        }
      }

      .content {
        .finances-box-content {
          border: 1px solid #ececf7;
          border-radius: 10px;
          padding: 30px;
          font-size: 16px;

          &-header {
            font-weight: bold;

            .finances-box-content-header-total {
              color: @mainBlueColor;
            }
          }

          &-content {
            font-size: 14px;

            &-date {
              font-size: 12px;
            }

            &-label {
              color: #a7a7fa;
            }
          }
        }
      }
    }
  }
}

.pass-fault-box {
  display: flex;
  background-color: #f0f4f6;

  .pass-fault-content {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 20px;
    font-size: 14px;
    font-weight: 700;
  }

  .pass-fault-button {
    color: #ec434c;
    background-color: #fdeced;
    border: none;
    font-weight: 700;
    font-size: 14px;

    &:hover {
      background-color: #fce3e4;
    }
  }

  .passed-fault-button {
    color: #fff;
    background-color: #f8df45;
    border: none;
    font-weight: 700;
    font-size: 14px;
  }

  .accept-fault-button {
    color: #fff;
    background-color: #15d55e;
    border: none;
    font-weight: 700;
    font-size: 14px;
    margin-right: 10px;
  }

  .deny-fault-button {
    color: #fff;
    background-color: #ec434c;
    border: none;
    font-weight: 700;
    font-size: 14px;
    margin-right: 10px;
  }
}

.faults-dropdown-show-icon {
  &::before {
    color: #3b4c60 !important;
  }
}
