.attachment {
  width: 100%;
  background-color: #F0F4F6;
  border-radius: 10px;
  padding: 7px;
  margin-bottom: 20px;

  &-icon {
    color: #C0D5E4;
    text-align: center;
  }

  &-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-actions {
    text-align: right;

    .edit-actions.ant-btn {
      padding: 2px;
      background: transparent;
    }
  }

  &-action {
    &.ant-btn {
      background: transparent !important;
      color: #C0D5E4;

      &:hover,
      &:active {
        color: rgba(0, 0, 0, 0.85)
      }
      &::after {
        box-shadow: none;
        border: none;
      }
    }

    .icon-more-vertical::before {
      transform: rotate(90deg);
    }
  }
}