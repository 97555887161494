.premises {
  &-gallery-slider {
    margin-bottom: 40px;
    background-color: #f0f4f6;
    border-radius: 20px;

    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
      color: unset;
      font-size: 30px;
      z-index: 20;
      padding: 30px;
      width: auto;
      height: auto;
    }

    .ant-carousel .slick-prev {
      left: 20px;
    }
    .ant-carousel .slick-next {
      right: 20px;
    }

    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover,
    .ant-carousel .slick-prev:focus,
    .ant-carousel .slick-next:focus {
      color: unset;
    }

    &-slide-wrapper {
      img {
        max-height: 350px;
        margin: 0 auto;
        display: block;
        max-width: 100%;
      }
    }
  }
  &-edit {
    .attachments,
    .agreements,
    .brokers,
    .faults,
    .tenantChanges {
      .smalllist-wrapper-row-icon {
        font-size: 16px;
      }
    }
  }

  &-stats {
    margin-top: 20px;

    &-elem {
      border-radius: 20px;
      padding: 25px;
      color: #fff;
      position: relative;
      height: 100%;
      display: block;

      &:before {
        width: 90%;
        height: 10px;
        display: block;
        content: ' ';
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      &-value {
        font-size: 3vw;
        font-weight: bold;
      }

      &:hover {
        opacity: 0.9;
      }

      &-active {
        opacity: 0.5;
      }
    }

    &-SALE {
      .premises-stats-elem {
        background-color: @mainGreenColor;

        &:before {
          background-color: darken(@mainGreenColor, 5%);
        }
      }
    }

    &-RENT {
      .premises-stats-elem {
        background-color: @mainBlueColor;

        &:before {
          background-color: darken(@mainBlueColor, 5%);
        }
      }
    }

    &-STATS {
      .premises-stats-elem {
        background-color: #ececf7;
        color: #3b4c60;

        &:before {
          background-color: #d5d5f3;
        }

        &-value {
          color: @mainBlueColor;
        }

        &.simulation {
          background-color: #e3edf4;
          color: #587999;

          &:before {
            background-color: #d6e1e9;
          }
        }
      }

      .simulation {
        .premises-stats-elem-value {
          font-size: 2vw;
        }
      }
    }
  }

  &-show {
    &-header {
      h1 {
        font-size: 28px;

        .dot {
          margin-left: 10px;
        }
      }

      &-price {
        font-size: 28px;
        text-align: right;

        span.sell {
          text-decoration: line-through;
        }
      }

      &-address {
        color: #b1cade;
        font-size: 14px;
      }
    }
    &-box {
      border: 1px solid #ececf7;
      padding: 20px;
      border-radius: 20px;
      margin-bottom: 20px;
      position: relative;

      &:before {
        width: 90%;
        height: 10px;
        display: block;
        content: ' ';
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      h2 {
        color: @mainBlueColor;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 18px;

        .font-icon {
          margin-right: 10px;
        }
      }

      &.agreements-box {
        background-color: @mainBlueColor;
        color: #ffffff;

        &:before {
          background-color: darken(@mainBlueColor, 5%);
        }

        h2 {
          color: #ffffff;
        }
      }

      &.price-box,
      &.costs-box {
        background-color: @mainGreenColor;
        color: #ffffff;

        &:before {
          background-color: darken(@mainGreenColor, 5%);
        }

        h2 {
          visibility: hidden;
        }

        .content {
          .title {
            font-size: 14px;
          }

          .value {
            font-size: 24px;
          }
        }
      }

      &.costs-box {
        .content {
          .title {
            font-size: 14px;
          }

          .value {
            font-size: 54px;
            font-weight: bold;
          }
        }
      }

      &.room-box {
        .room-box-costs {
          margin-bottom: 20px;
          font-size: 16px;

          .value {
            color: @mainBlueColor;
            font-weight: bold;
          }
        }

        .rooms-list-room {
          background: transparent;
          border: 1px solid #f0f4f6;

          &-name {
            margin-top: 15px;
          }

          &-size {
            margin-bottom: 15px;
          }

          &-costs {
            &-rent,
            &-charges {
              font-weight: bold;
              color: @mainBlueColor;
            }

            &-deposit {
              color: #8fb3d1;
            }
          }
        }
      }

      &.info-box {
        .content {
          .label {
            color: #a7a7fa;
          }
        }
      }

      &.attachments-box {
        .attachment {
          line-height: 30px;

          &-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .ant-btn {
            background: transparent !important;
            color: #c0d5e4;

            &:hover,
            &:active {
              color: rgba(0, 0, 0, 0.85);
            }
            &::after {
              box-shadow: none;
              border: none;
            }
          }
        }
      }

      .smalllist-wrapper {
        .ant-table-tbody td {
          background: transparent;
          border-top: 1px solid #ececf7;
          border-bottom: 1px solid #ececf7;
          padding: 12px;

          &:first-child {
            border-left: 1px solid #ececf7;
          }

          &:last-child {
            border-right: 1px solid #ececf7;
          }
        }
      }

      &.brokers-box {
        .ant-table-tbody td {
          background: #ececf7;
          border-top: 1px solid #ececf7;
          border-bottom: 1px solid #ececf7;

          &:first-child {
            border-left: 1px solid #ececf7;
          }

          &:last-child {
            color: @mainBlueColor;
            font-weight: bold;
            border-right: 1px solid #ececf7;
          }

          .premises-broker-phone {
            color: #a7a7fa;
          }

          .icon-percent {
            font-size: 13px;
          }
        }
      }

      &.tranches-box {
        .ant-table-tbody td:first-child {
          padding: 12px 12px 12px 17px;
        }
      }

      &.agreements-box {
        .single-agreement {
          border: 1px solid #6565f7;
          border-radius: 20px;
          padding: 20px;
          margin-bottom: 20px;

          &-name {
            font-size: 18px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 20px;
          }

          .label {
            color: #a7a7fa;
          }

          &-clients {
            margin-bottom: 20px;
            margin-top: 20px;
          }

          &-header-info {
            text-align: right;

            .ant-btn {
              background-color: #ececf7;
              color: @mainBlueColor;
              font-size: 12px;
              font-weight: bold;
            }
          }

          h2 {
            font-size: 16px;
            margin-bottom: 10px;
          }

          .attachment {
            line-height: 30px;
            background: transparent;
            border: 1px solid #7777f6;

            &-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &-actions {
              text-align: right;
              .ant-btn {
                background: transparent !important;
                color: #c0d5e4;

                &:hover,
                &:active {
                  color: rgba(0, 0, 0, 0.85);
                }
                &::after {
                  box-shadow: none;
                  border: none;
                }
              }
            }
          }

          .ant-table-content {
            background-color: @mainBlueColor;
            color: #fff;

            .ant-table-tbody td,
            .ant-table-tbody tr:hover td {
              background: #7777f6;
              border-top: 1px solid #7777f6;
              border-bottom: 1px solid #7777f6;

              &:first-child {
                border-left: 1px solid #7777f6;
              }

              &:last-child {
                border-right: 1px solid #7777f6;
              }

              .icon-tenant {
                font-size: 15px;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.premise-investment-address {
  background: #f0f4f6;
  padding: 20px 30px;
  border-radius: 20px;
  margin-bottom: 20px;
  font-size: 16px;

  .ant-col {
    align-self: center;
  }
}

.paid-tranche {
  padding: 20px 30px;
  text-align: center;

  &-header {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
  }
  &-info {
    margin-bottom: 15px;
    color: #b1cade;
  }

  &-buttons .ant-btn {
    width: 100%;
  }
}

.import-button {
  min-width: 160px;
  min-height: 48px;
  margin-right: 20px;
}

.assignCard {
  padding: 40px;
}

@media only screen and(max-width: 576px) {
  .import-button {
    min-width: 120px;
  }
  .assignCard {
    padding: 24px;
  }
  .assignSingleCard {
    padding: 24px;
  }
}

@media only screen and(max-width: 350px) {
  .assignCard {
    padding: 4px;
  }
}

.owner-box {
  .content {
    .owner-box-content {
      display: flex;
      justify-content: space-between;
      border: 1px solid #f0f4f6;
      border-radius: 10px;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 40px;
      padding-right: 40px;

      .item {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 5px;
        flex: 1;
      }
    }
  }
}

.shares-box-right-col {
  h2 {
    text-align: right;
  }

  .content {
    div {
      text-align: right;
    }
  }
}

@media only screen and(max-width: 576px) {
  .shares-box-right-col {
    h2 {
      text-align: left;
      margin-top: 40px;
    }

    .content {
      div {
        text-align: left;
      }
    }
  }

  .owner-box {
    .content {
      .owner-box-content {
        display: block;
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .premises-show-box.owner-box h2 i {
    display: none;
  }
}
