.single-package {
  text-align: center;
  font-size: 14px;
  padding: 30px;
  margin-bottom: 20px;

  &-header {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &-investments {
    color: #252d38;
    font-size: 16px;
    margin-bottom: 20px;
  }

  &-price {
    font-weight: bold;
    font-size: 54px;
  }
  &-addnotation {
    font-size: 12px;
    color: #8fb3d1;
    margin-bottom: 30px;
  }

  &-limits {
    text-align: left;
    width: 80%;
    margin: 0 auto 30px;

    &-single-value {
      font-weight: bold;
    }

    &-addnotation {
      font-size: 10px;
      color: #8fb3d1;
    }
  }

  &-action {
    button {
      width: 160px;
      height: 48px;
    }

    button[disabled] {
      color: rgba(0, 0, 0, 0.25) !important;
      background: #f5f5f5 !important;
      border-color: #f5f5f5 !important;
    }
  }
}

.header-package-button {
  background-color: #fff !important;
  height: 32px;
  border-radius: 16px;
  color: #3b4c60 !important;
  border-color: transparent !important;

  font-family: Overpass;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: right;

  .header-package-button-actual {
    color: #5353f4;
    margin-left: 4px;
  }

  .header-package-button-limit {
    color: #b1cade;
  }

  .icon-arrow-simply-right {
    margin-left: 5px;

    &::before {
      transform: rotate(180deg);
      margin-bottom: 3px;
    }
  }
}

.packages-help {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;

  a {
    color: @mainBlueColor;
    font-weight: bold;

    .icon-arrow-simply-right {
      margin-left: 5px;

      &::before {
        transform: rotate(180deg);
      }
    }
  }
}

.account-edit-packages {
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;
  color: #fff;
  background-color: #252d38;

  @media only screen and (min-width: 576px) {
    margin: 30px;
    padding: 50px;
  }

  &-header {
    font-size: 34px;
    font-weight: 600;

    &-price {
      font-size: 24px;

      &-addnotation {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 30px;
      }

      @media only screen and (min-width: 576px) {
        text-align: right;
      }
    }
  }

  &-details {
    &-investments {
      color: #252d38;
      font-size: 16px;
      margin-bottom: 20px;
    }

    &-limits {
      text-align: left;

      &-single-value,
      &-single-investments-value,
      &-single-investments-label {
        font-weight: bold;
      }

      &-single-investments-label,
      &-single-investments-value {
        margin-bottom: 10px;
      }

      &-addnotation {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    &-actions {
      margin-top: 10px;

      button {
        margin-top: 10px;
      }

      @media only screen and (min-width: 576px) {
        margin-top: 0;
        text-align: right;

        button {
          margin-left: 10px;
          margin-top: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .choose-investment-btn {
    width: 135px;
    margin-right: 5px;

    .investment-name {
      width: 95px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .header-package-title {
    display: none !important;
    margin: 0 !important;
  }

  .header-package-button-actual {
    margin-left: 0 !important;
  }

  .choose-investment-btn {
    width: 110px;
    margin-right: 5px;

    .investment-name {
      width: 70px;
    }
  }
}

.choose-investment-btn {
  i {
    position: absolute;
    display: inline-block;
    vertical-align: baseline;
  }
}

@media only screen and (max-width: 350px) {
  .header-package-button {
    padding-left: 10px;
    padding-right: 5px;
  }

  .choose-investment-btn {
    padding-left: 10px;
    padding-right: 10px;
    width: 100px;

    .investment-name {
      width: 65px;
    }
  }

  .header-notifications-button {
    padding-left: 10px;
    padding-right: 5px;
  }

  .header-options-button {
    padding-right: 10px;
  }
}
