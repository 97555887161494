.mass-actions {
  &-email {
    .ant-modal-confirm-btns {
      text-align: center;

      .ant-btn-default {
        margin-right: 340px;
        margin-top: 16px;
        margin-bottom: 16px;
        width: 175px;
        height: 48px;
      }

      .ant-btn-primary {
        width: 175px;
        height: 48px;
      }
    }

    .ant-modal-confirm-title {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;

      color: #3b4c60;
      margin-top: 31px;
      margin-bottom: 26px;
    }

    &-annotation {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;

      color: #b1cade;
      margin-bottom: 40px;
    }
  }
  &-sms {
    .ant-modal-content {
      padding: 20px;
    }

    .ant-btn-default {
      margin-right: 340px;
      margin-top: 16px;
      margin-bottom: 16px;
      width: 175px;
      height: 48px;
    }

    .ant-modal-title {
      margin-top: 20px;
      font-family: Overpass;
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0px;
      text-align: left;
    }

    .ant-modal-confirm-btns {
      text-align: center;

      .ant-btn-default {
        margin-right: 340px;
        margin-top: 16px;
        margin-bottom: 16px;
        width: 175px;
        height: 48px;
      }

      .ant-btn-primary {
        width: 175px;
        height: 48px;
      }
    }

    &-textarea {
      padding: 14px;
      padding-left: 17px;
      padding-right: 17px;
      height: 200px !important;
      resize: none;

      background: #ffffff;
      border: 1px solid #c0d6e5;
      border-radius: 10px;
      font-size: 16px;
    }

    .ant-modal-confirm-title {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;

      color: #3b4c60;
      margin-top: 31px;
      margin-bottom: 26px;
    }

    &-signs-counter {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;

      color: #b1cade;
    }

    &-annotation {
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;

      color: #b1cade;
      margin-bottom: 40px;
    }
  }
}

//////////////////////

.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}

.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px;

  .rdw-dropdown-carettoclose {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-bottom: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  .rdw-dropdown-carettoopen {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-top: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
}

.rdw-dropdown-optionwrapper {
  z-index: 100;
  position: relative;
  border: 1px solid #f1f1f1;
  width: 98%;
  background: white;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;

  .rdw-dropdownoption-active {
    background: #f5f5f5;
  }

  .rdw-dropdownoption-default {
    min-height: 25px;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
}
