@import '~antd/dist/antd.less';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import './styles/investments.less';
@import './styles/rooms.less';
@import './styles/smalllist.less';
@import './styles/premises.less';
@import './styles/attachment.less';
@import './styles/gallery.less';
@import './styles/tenantChanges.less';
@import './styles/print.less';
@import './styles/faults.less';
@import './styles/notifications.less';
@import './styles/dashboard.less';
@import './styles/stats.less';
@import './styles/datagrid.less';
@import './styles/dot.less';
@import './styles/tpay.less';
@import './styles/packages.less';
@import './styles/inputs.less';
@import './styles/tags.less';
@import './styles/demo.less';
@import './styles/themes.less';
@import './styles/mobile.less';
@import './styles/subscription.less';
@import './styles/firstLogin.less';
@import './styles/massActions.less';
@import './styles/messages.less';
@import './styles/options.less';
@import './styles/contacts.less';
@import './styles/counters.less';
@import './styles/settlements.less';
@import './styles/balance.less';
@import './styles/resolutions.less';

@mainGreenColor: #15d55e;
@mainBlueColor: #5353f4;
@mainBlackColor: #3b4c60;

body {
  font-family: 'Overpass', sans-serif;
  overflow-y: auto !important;
}

.loading {
  text-align: center;
  padding: 20% 50px;
  margin: 20px 0 20px;
}

[ant-click-animating-without-extra-node]:after {
  animation: 0s !important;
}
.ant-btn {
  box-shadow: none !important;
  text-shadow: none !important;
}
.ant-btn-primary {
  min-width: 160px;
  min-height: 48px;
}
.ant-space-item .button-secondary {
  min-width: 160px;
  min-height: 48px;
}
.ant-btn-lg {
  min-width: 160px;
  min-height: 48px;
}
.button-no-border {
  border: none;
}
//icons
[class*='icon-']:before {
  vertical-align: middle;
  // margin-bottom: 3px;
}
.icon-arrow-short-right::before {
  transform: rotate(180deg);
}

.icon-arrow-simply-down::before {
  transform: rotate(270deg);
}

.icon-arrow-simply-right::before {
  transform: rotate(180deg);
}

.icon-arrow-simply-down.rotate-180::before {
  transform: rotate(90deg);
}

.ant-btn.ant-btn-background-ghost::after {
  border: none !important;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

@media only screen and (min-width: 768px) {
  .login-form-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-form-wrapper {
    width: 300px;
  }
}

.login-form-wrapper,
.reset-password-form-wrapper {
  max-width: 300px;
  margin: 30% auto 30%;
  .logo {
    margin: 50px auto;
    display: block;
  }
}

.ant-layout-sider {
  background-color: #252d38;
  background-image: url('./static/images/menuBackground.png');
  background-position: top left;
  background-repeat: no-repeat;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  min-height: 100vh;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 5;

  .menu-header {
    .logo {
      height: 155px;
      display: block;
      position: relative;

      .border-wrapper {
        position: absolute;
        top: 32px;
        left: 32px;
        width: 108px;
        height: 108px;
        display: block;
        background-color: @mainGreenColor;
        border-radius: 50%;
      }
      .image-wrapper {
        display: block;
        margin: 5px;
        background-size: cover;
        background-position: center center;
        width: 98px;
        height: 98px;
        border-radius: 50%;
      }
    }

    .text {
      margin: 0 20px 20px;
      color: #fff;
      font-size: 14px;

      .title {
        font-size: 16px;
        font-weight: bold;
      }

      .address {
        margin-top: 14px;
        font-size: 12px;
        color: #587999;
      }
    }
  }

  .ant-menu {
    background: transparent;
    font-weight: bold;
    font-size: 14px;

    .ant-menu-item-selected {
      background-color: rgba(255, 255, 255, 0.05) !important;
    }

    .font-icon {
      margin-right: 10px;
    }
  }

  .ant-menu-item-selected.help {
    .ant-menu-item-icon {
      color: #7a9bbb;
    }

    .ant-menu-title-content {
      color: #7a9bbb;
    }
  }

  .help {
    margin-top: 30px;
    color: #587999;

    &:hover,
    &:active {
      color: #7a9bbb;
    }

    span a {
      color: #587999;

      &:hover,
      &:active {
        color: #7a9bbb;
      }
    }
  }
}

.login-form .login-form-button,
.reset-password-form .reset-password-form-button {
  width: 100%;
}
.login-form-forgot {
  text-decoration: underline;
}
.aside {
  .logo {
    padding-left: 30px;
    padding-top: 30px;
  }
  .carousel {
    padding-top: 20%;
    padding-bottom: 50px;

    .slick-dots {
      bottom: -40px;
      margin-bottom: 25px;

      li {
        width: 9px;
        button {
          height: 9px;
          border-radius: 4px;
        }

        &.slick-active {
          width: 36px;
          button {
            background: @mainGreenColor;
          }
        }
      }
    }
  }
  .slide {
    color: #8fb3d1;

    h1 {
      color: @mainGreenColor;
      font-weight: bold;
      font-size: 46px;
      margin-left: 60px;
      line-height: 58px;
      margin-right: 15px;
    }

    p {
      margin-left: 60px;
      margin-right: 15px;
      font-size: 24px;
      line-height: 31px;
    }
  }
}

.ant-layout {
  background-color: #f0f4f6;

  .site-layout {
    background-color: #fff;

    .ant-layout-header {
      background-color: #f0f4f6;
    }
  }
}

.delete-confirm-modal {
  text-align: center;

  .ant-modal-confirm-title {
    font-size: 20px;
    font-weight: bold;
  }

  .ant-modal-confirm-btns {
    float: none;

    button {
      width: 115px;
      min-width: 0;
      min-height: 0;
    }
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #587999;
}
.ant-checkbox-inner {
  border-radius: 4px;
  background-color: #fff;
  border-color: #587999;
}
.ant-checkbox-checked::after {
  border-color: transparent;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #587999;
}
.ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 4px;
  background-color: #587999;
  border-color: #587999;
  &::after {
    color: #fff;
  }
}

.show-layout {
  .left {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;

    .logo {
      img {
        width: 100%;
      }
    }

    .name {
      font-size: 24px;
      margin-top: 5px;
      margin-bottom: 25px;
    }

    .position {
      margin-bottom: 20px;
      font-size: 16px;
      color: #c5d6e3;
    }

    .buttons {
      .call {
        margin-bottom: 10px;
      }

      .write {
        button {
          background-color: @mainBlueColor;
          border-color: @mainBlueColor;
        }
      }

      button {
        width: 100%;

        i {
          padding-right: 5px;
        }
      }
    }
  }
  .right {
    .ant-card {
      border: 1px solid #ececf7;
      margin-bottom: 10px;
      margin-top: 10px;

      &-head {
        border-bottom: none;
        padding-right: 60px;

        h2 {
          font-size: 18px;
          font-weight: bold;
          color: @mainBlueColor;
          margin-bottom: 0;
          white-space: initial;

          i {
            @media only screen and (max-width: 425px) {
              display: none;
            }
            margin-right: 10px;
            font-size: 25px;
          }
        }
      }

      &.access .ant-card-body {
        h2 {
          color: @mainBlueColor;
          font-size: 16px;
        }

        .value {
          font-weight: bold;
        }
      }

      .ant-card-body {
        padding-left: 60px;
        padding-right: 60px;
        @media only screen and (max-width: 425px) {
          padding-left: 25px;
          padding-right: 25px;
        }

        .title {
          color: @mainBlueColor;
        }
      }
    }
  }
}

.form-create {
  .form-create-main-card > .ant-card-body {
    padding: 0;
  }

  .create-form-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .ant-tabs-nav-list {
    margin-top: 20px;
    font-size: 16px;
  }

  .ant-tabs-tab:hover,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @mainBlueColor;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .form-section {
    margin-bottom: 20px;
  }

  .create-form-password-card {
    padding-top: 30px;
    padding-bottom: 30px;
    border: 1px solid @mainBlueColor;

    .ant-card-body {
      padding-left: 0;
      padding-right: 0;
    }
    .create-form-button-send-password {
      width: 100%;
      color: #fff;
      background-color: @mainBlueColor;
      border: 0;
      i {
        padding-left: 10px;
      }
    }
    .create-form-button-send-password:hover {
      background-color: #6464f5 !important;
    }

    .send-password-image-wrapper {
      text-align: right;

      img {
        max-width: 300px;
        width: 100%;
      }
    }
  }
}

.button-secondary {
  color: @mainBlueColor;
  background-color: #ececf7;
  border-color: #ececf7;
  font-weight: bold;

  &:focus,
  &:hover,
  &:active {
    background-color: #d5d5f3;
    border-color: #d5d5f3;
    color: @mainBlueColor;
  }

  &.active {
    background-color: @mainBlueColor;
    border-color: @mainBlueColor;
    color: #ececf7;
  }
}

.dropdown-menu-wrapper {
  i {
    margin-right: 5px;
  }
}
.ant-page-header {
  width: 100%;
}
.ant-page-header-back {
  background-color: #ececf7;
  border-radius: 5px;
  transition: all 0.3s;

  &:hover {
    background-color: #d5d5f3;
  }

  .ant-page-header-back-button {
    padding: 16px !important;

    i {
      color: @mainBlueColor;
    }
  }
}
.ant-page-header-heading-title {
  font-weight: normal;
  font-size: 24px;

  span.header-subtitle {
    color: @mainBlueColor;
  }
}

.ant-page-header-heading-extra {
  button {
    i {
      padding-left: 5px;
    }
  }
}

.userIcon-wrapper {
  .userIcon-circle {
    max-width: 300px;
    width: 100%;
    margin: 50px auto;
  }
}

.ant-layout-sider-collapsed {
  .menu-header {
    display: none;
  }
  .ant-menu-item {
    display: none;
  }
}
.site-layout-header {
  padding: 0;
  .trigger {
    cursor: pointer;
    margin-left: 20px;
    color: @mainBlueColor;
  }

  .site-layout-header-actions {
    float: right;
    margin-right: 10px;

    button {
      background-color: transparent;

      i {
        color: #c0d6e5;
      }

      &:hover,
      &:focus {
        i {
          opacity: 1;
          color: #3b4c60;
        }
      }
    }
  }
}

.form-actions {
  button {
    max-width: 160px;
    width: 100%;

    i {
      padding-right: 5px;
    }
  }
  &-save-wrapper {
    text-align: right;
  }
}

.upload-avatar-wrapper {
  position: relative;
  img {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 300px;
  }

  .change-button {
    position: absolute;
    bottom: 0;
    right: 20px;
    opacity: 0;
    transition: 0.5s all ease-in-out;

    i {
      padding-left: 5px;
    }
  }

  &:hover {
    .change-button {
      opacity: 1;
    }
  }
}

.ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture-card-container {
  width: 100%;
  height: 250px;
}

.premises-custom-create-link {
  color: rgba(0, 0, 0, 0.85) !important;
}

.button-group {
  button {
    border-radius: 0;
  }

  button:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  button:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.ant-picker-large {
  padding: 16px 12px 4px 11px;

  .ant-picker-suffix {
    line-height: 1.5715;
  }
}

.costs-box {
  background-color: @mainGreenColor;
  color: #ffffff;

  &:before {
    background-color: darken(@mainGreenColor, 5%);
  }

  h2 {
    visibility: hidden;
  }

  .content {
    .title {
      font-size: 14px;
    }

    .value {
      font-size: 24px;
    }
  }
}

.broker-icon {
  background-color: #f0f4f6;
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: text-top;
}

.ant-modal-mask,
.ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  color: #b1cade;
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-modal-confirm-body {
  .ant-modal-confirm-content {
    color: #b1cade;
  }

  .ant-modal-confirm-title {
    color: #3b4c60;
  }
}

.ant-modal-confirm-btns {
  .ant-btn-dangerous {
    color: #fff;
    background: #ff4d4f;
    border-color: #ff4d4f;
  }
}

.not-found {
  .error-wrapper {
    text-align: center;
    margin-top: 10%;
    color: #3b4c60;

    img {
      max-width: 100%;
      margin-bottom: 10px;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
    }
  }
}

.ant-input-number-lg input {
  padding: 0;
  height: initial;
}

.ant-upload-list-picture-card-container + .ant-upload-select-picture-card {
  display: none;
}

.chat-button-wrapper {
  position: fixed;
  bottom: 100px;
  right: 20px;

  .ant-btn {
    padding: 0;
    overflow: hidden;
    height: auto;
    background: none;
    width: auto;
  }
}

#czater-contener {
  bottom: -0px !important;

  .czater-button {
    display: none !important;
  }
}

.ant-form-horizontal .permissions .ant-form-item-label {
  text-align: left;
  width: 200px;
  flex: none;
}

.label-narrow {
  margin-bottom: -5px;
  margin-top: 0;
}

.ant-form-horizontal .label-narrow .ant-form-item-label {
  width: 100px;
  label {
    color: #3b4c60;
  }
}

.ant-form-horizontal .permissions .ant-col.ant-form-item-control {
  flex: 1;
}

.gallery > .ant-row > .ant-col-offset-2,
.create-form-wrapper > .ant-row > .ant-col-offset-2,
.create-form-wrapper > .ant-row > .ant-col-offset-4 {
  margin-top: 8.3333333%;
  margin-bottom: 8.3333333%;
}

.header-menu-wrapper {
  .premises-custom-create-link {
    color: rgb(88, 121, 153) !important;
  }
  .font-icon.icon-logout::before {
    transform: rotate(90deg);
  }
}

//layout fix
.site-layout {
  margin-left: 0px;
  transition: margin-left 0.2s;
}

.ant-layout-sider {
  transition: all 0.1s;
  z-index: 5;
  &:not(.ant-layout-sider-collapsed) + .site-layout {
    .site-layout-header {
      text-align: right;

      .trigger {
        margin-right: 20px;
      }
    }
    .site-layout-header-actions {
      display: none;
    }
  }
}
@media only screen and (min-width: 768px) {
  .site-layout {
    margin-left: 236px;
  }

  .ant-layout-sider {
    z-index: 4;
    &.ant-layout-sider-collapsed + .site-layout {
      margin-left: 30px;
      .site-layout-header {
        text-align: left;

        .trigger {
          margin-right: 0;
        }
      }
      .site-layout-header-actions {
        display: block;
      }
    }

    &:not(.ant-layout-sider-collapsed) + .site-layout {
      .site-layout-header {
        text-align: left;
      }
      .site-layout-header-actions {
        display: block;
      }
    }
  }
}

.reset-password-form-back-wrapper i::before {
  vertical-align: top;
  margin-top: 4px;
}

.account-expired-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;

  .account-expired-popup {
    width: 200px;
    margin: 20% auto 0;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
    background: #fff;
  }
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: 15px 24px 8px 35px;
  position: relative;

  .font-icon {
    position: absolute;
    left: 14px;
    top: 14px;
    color: #3b4c60;
  }
}

.button-no-border:hover,
.button-no-border:focus {
  color: #3b4c60;
  background: transparent;
}

.rodo-content {
  white-space: pre-wrap;
  max-width: 800px;
  width: 100%;
  margin: 10px auto;
  padding: 10px;

  font-family: 'Overpass';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

.generate-password {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}

.call-btn {
  background-color: @mainBlueColor;
  border: 0 !important;
}

.call-btn:hover {
  background-color: darken(@mainBlueColor, 5%);
}

.email-btn {
  background-color: #252d38 !important;
  border: 0 !important;
}

.email-btn:hover {
  background-color: #141c27 !important;
}

.form-actions {
  position: fixed;
  background-color: #fff;
  bottom: 0;
  padding-bottom: 24px;
  padding-top: 20px;
  border-top: 1px solid #f0f0f0;
  width: calc(100% + 100px);
  margin-left: -100px;
  padding-left: 100px;
  z-index: 3;

  .form-actions-save-wrapper {
    position: fixed;
    right: 24px;
    max-width: none;
  }
}

@media only screen and (max-width: 576px) {
  .form-actions {
    .form-actions-save-wrapper {
      right: 10px;
    }
  }
}

.ant-page-header-content {
  margin-bottom: 50px;
}

.fault-template {
  width: 100%;
  height: 48px;
  background-color: #f0f4f6;
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Overpass;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  border-radius: 10px;

  button {
    background-color: #f0f4f6 !important;
    border: 0;

    &:hover,
    &:focus {
      background-color: #f0f4f6 !important;
      color: #7f8fa4;
    }

    i::before {
      transform: rotate(90deg);
    }
  }
}

.fault-template-empty {
  text-align: center;
  border: 1px dashed rgb(192, 214, 229);
  color: rgb(192, 214, 229);
  padding: 30px;
  border-radius: 20px;
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .ant-btn-lg,
  .ant-btn-primary,
  .ant-space-item .button-secondary {
    min-width: 120px;
  }
}

.delete-btn {
  position: absolute;
  bottom: 50px;
  left: 80px;

  color: #ec434c;
  border: 0;
  background-color: #fff;
  font-weight: 700;

  &:hover,
  &:active,
  &:focus {
    color: #ec434c;
    background-color: #fff;
  }
}

.create-form-password-card {
  margin-bottom: 120px;
}

@media only screen and (max-width: 1199px) {
  .delete-btn {
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 85px;
  }

  .ant-tabs-tab {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  .form-create-main-card {
    padding-bottom: 30px;
  }

  .create-form-password-card {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .form-create-main-card {
    padding-bottom: 50px;
  }

  .create-form-password-card {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 576px) {
  .form-create-main-card {
    padding-bottom: 60px;
  }

  .ant-page-header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ant-card-body {
    padding: 4px;
  }

  .ant-tabs-tab {
    margin: 0 !important;
  }
}

.ant-table-cell::before {
  content: none !important;
}

.show-link {
  color: #3b4c60;
}

.show-link:hover {
  color: #3b4c60;
}

.form-create .ant-page-header {
  margin-bottom: 80px;
}

.ant-table-placeholder td {
  border-bottom: 0 !important;
}

.ant-upload-select-picture-card {
  background-color: #fff !important;
  border-color: #c0d6e5 !important;

  &:hover {
    border-color: #587999 !important;
  }
}

.ant-tabs-tab-btn {
  color: #b1cade;
  font-family: Overpass;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.datagrid-filter-modal-button-wrapper {
  .ant-btn-danger {
    background-color: #ec434c1a;
    border: 0;
    color: #ec434c;
    font-weight: 700;

    &:hover {
      background-color: rgba(236, 67, 76, 0.15);
    }
  }
}

.counters-edit-btn {
  background-color: @mainBlueColor !important;
  border-color: @mainBlueColor !important;

  &:hover {
    background-color: lighten(@mainBlueColor, 5%) !important;
    border-color: lighten(@mainBlueColor, 5%) !important;
  }
}

.secondary-bg {
  background-color: @mainBlueColor;

  .shares-tooltip-header {
    color: #a7a7fa;
  }
}

.pass-fault-modal {
  .ant-modal-confirm-btns {
    display: flex;

    .button-secondary {
      margin-right: auto;
    }

    .ant-btn.ant-btn-lg {
      width: 160px !important;
    }
  }
}

.rdw-editor-wrapper {
  border: 1px solid #c0d6e5;
  border-radius: 10px;
  padding: 10px;

  .rdw-editor-toolbar {
    border: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #c0d6e5;

    .rdw-dropdown-selectedtext {
      color: #3b4c60;
    }
  }

  .rdw-editor-main {
    padding-left: 10px;
    padding-right: 10px;
    min-height: 300px;
  }
}

.housing-fault-template-wrapper {
  padding: 8.3333333%;
}

@media only screen and (max-width: 1199px) {
  .housing-fault-template-wrapper {
    padding: 4%;
  }
}

.rdw-center-aligned-block > .public-DraftStyleDefault-block > span {
  text-align: center;
  width: 100%;
  display: block;
}

.rdw-right-aligned-block > .public-DraftStyleDefault-block > span {
  text-align: right;
  width: 100%;
  display: block;
}

.rdw-justify-aligned-block > .public-DraftStyleDefault-block > span {
  text-align: justify;
  width: 100%;
  display: block;
}

.rdw-embedded-modal {
  height: fit-content;
}

.rdw-link-modal {
  height: fit-content;
}

.rdw-image-wrapper {
  .rdw-image-modal {
    .rdw-image-modal-upload-option {
      .rdw-image-modal-upload-option-label {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        text-align: center;
      }
    }
  }
}

.import-info-wrapper {
  margin-top: 60px;
  padding: 40px;
}

@media only screen and (max-width: 576px) {
  .import-info-wrapper {
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .ant-card.ant-card-bordered.form-create-main-card {
    min-height: 420px;
  }
}

.change-acc-table {
  table {
    table-layout: fixed !important;
  }

  tr {
    td {
      padding-top: 8px;
      padding-bottom: 6px;
    }

    td:nth-child(2) {
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 16px;
      padding-right: 0;
    }

    td:nth-child(3) {
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 8px;
      padding-right: 0;
    }
  }

  .change-acc-delete-dropdown {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 8px !important;
    width: 35px !important;
  }

  .change-acc-btn {
    padding-right: 5px !important;
    padding-bottom: 8px;
    width: 70px !important;

    .change-acc-btn-arrow {
      background-color: transparent;
      border: 0;
      color: #c0d5e4;

      &:hover {
        color: #3b4c60;
      }
    }
  }
}

.messages-select-ids-table-wrapper {
  // border: 1px solid #c0d6e5;
  // border-radius: 10px;

  .ant-table-thead {
    tr {
      th {
        padding: 12px 8px;
        font-size: 12px;
        font-weight: 700;
        background-color: #ffffff;

        border-top: 1px solid #c0d6e5;
        border-bottom: 1px solid #c0d6e5;
      }

      th:first-child {
        border-left: 1px solid #c0d6e5;
      }
      th:last-child {
        border-right: 1px solid #c0d6e5;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        padding: 4px 8px;
        font-size: 12px;
        border-bottom: none;
      }

      td:first-child {
        border-left: 1px solid #c0d6e5;
      }
      td:last-child {
        border-right: 1px solid #c0d6e5;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid #c0d6e5;
      }

      td:first-child {
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-bottom-right-radius: 10px;
      }
    }

    tr.ant-table-row-selected {
      td {
        background-color: #f0f4f7;
      }
    }
  }
}

.message-scheduled-info {
  background-color: #ec434c1a;
  color: #ec434c;
  font-size: 12px;
  font-weight: 700;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 12px;

  .danger-icon {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.ant-dropdown-menu-item-icon {
  min-width: 16px !important;
}

.messages-select-ids-table-wrapper {
  overflow-x: auto;
}

.total-surface-card {
  margin-left: -40px;
  margin-right: -40px;
}

.total-surface-overview-card {
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f4f6;
  padding-bottom: 5px;
}

.investments-list-name-wrapper {
  display: flex;
  align-items: center;
  margin: 4px 0;
}

@media only screen and (max-width: 576px) {
  .total-surface-overview-card {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 425px) {
  .total-surface-card {
    margin-left: 0;
    margin-right: 0;
  }
}

.ant-tabs-nav-wrap::before {
  box-shadow: none !important;
}
.ant-tabs-nav-wrap::after {
  box-shadow: none !important;
}

.member-settlements-bankAccounts {
  .ant-card-body {
    padding: 16px;
    padding-bottom: 0 !important;
  }
}

@primary-color: #15D55E;@border-radius-base: 10px;@heading-color: #3B4C60;@error-color: #EC434C;@text-color: #3B4C60;@text-color-secondary: #3B4C60;