.account-subscription {
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;
  color: #fff;

  .email-us {
    display: flex;

    .email-us-text {
      flex: 1;
      font-family: Overpass;
      font-size: 16;
      font-weight: 400;
      letter-spacing: 0;
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (min-width: 576px) {
    padding: 30px;
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 50px;
  }

  &-header {
    font-size: 34px;
    font-weight: 600;
    font-family: 'Overpass';

    @media only screen and (max-width: 576px) {
      text-align: center;
      margin-bottom: 20px;
    }

    &-price {
      font-size: 24px;

      &-addnotation {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }

      @media only screen and (min-width: 576px) {
        text-align: right;
      }
      &-paydate {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
      }

      @media only screen and (min-width: 576px) {
        text-align: right;
      }
    }
  }

  &-details {
    &-limits {
      text-align: left;

      &-single-value,
      &-single-investments-value,
      &-single-investments-label {
        font-weight: bold;
      }

      &-single-investments-label,
      &-single-investments-value {
        margin-bottom: 10px;
      }

      &-single-label,
      &-single-value {
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 10px;
      }

      &-addnotation {
        &-wrapper {
          margin-top: 32px;
        }
        &-details {
          font-size: 10px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      button {
        margin-top: 10px;
      }
    }
  }
}

.subscription-community-admin {
  &-header {
    margin-bottom: 40px;
  }
}

.subscription-actions-col {
  align-self: flex-end;
}

@media only screen and (max-width: 1400px) {
  .subscription-actions-col {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .account-subscription {
    .email-us {
      display: block;
      text-align: center;

      .email-us-text {
        display: block;
      }

      button {
        margin-top: 15px;
      }
    }
  }
}
