.tpay-settings {
  background-color: #072653;
  border-radius: 20px;
  padding: 50px;
  margin: 30px;

  h2 {
    color: #15d55e;
    font-weight: bold;
  }

  .ant-form-item {
    .ant-input {
      background-color: #072653;
      color: #587999;
    }

    label {
      background-color: #072653;
    }
  }

  .button-wrapper {
    .ant-btn {
      width: 100%;
    }
  }

  .image-wrapper {
    text-align: right;
  }
}

.methods {
  padding: 20px;

  @media only screen and (min-width: 576px) {
    padding: 100px;
  }

  .method {
    padding: 10px;
    border: 2px solid #f0f4f6;
    border-radius: 10px;
    height: 90px;
    margin-bottom: 20px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active,
    &:hover {
      border: 2px solid #15d55e;
    }

    img {
      max-width: 100%;
      width: auto;
    }
  }
}

.tpay-logo-wrapper {
  margin-right: 80;
  display: flex;
  margin-right: 20px;
}

.payment-redirect-payment-button {
  min-width: 249px !important;
  height: 48px;
  color: #fff;
  border-color: #15d55e !important;
  background: #15d55e !important;
}

.payment-redirect-payment-button:hover {
  background: #26e66f !important;
}

.payment-redirect-payment-button:disabled {
  color: rgba(255, 255, 255, 0.5);
  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }
}

@media only screen and (max-width: 576px) {
  .tpay-settings {
    padding: 30px;
    margin: 10px;
  }
}
