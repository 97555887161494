.gallery-wrapper {
  padding: 20px;
  border: 1px dashed #C0D6E5;
  border-radius: 10px;

  &-image {
    border: 3px solid #C0D6E5;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
    position: relative;
    margin-bottom: 20px;

    img {
      max-width: 100%;
    }
  }

  &-action {
    position: absolute;
    top: 6px;
    right: 6px;

    &.ant-btn {
      background: #fff !important;
      color: @mainBlackColor;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      opacity: 0.9;

      &:hover,
      &:active {
        color: rgba(0, 0, 0, 0.85)
      }
      &::after {
        box-shadow: none;
        border: none;
      }
    }

    .icon-more-vertical::before {
      transform: rotate(90deg);
    }
  }

  &-add {
    border: 3px solid #C0D6E5;
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
    margin-bottom: 20px;

    .ant-upload {
      width: 100%;
      height: 100%;

      .ant-btn {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding-top: 40%;
        padding-bottom: 40%;
      }
    }
  }
}