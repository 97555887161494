.investments {
  &-form {
    &-header {
      &-title {
        &-name {
          color: @mainBlueColor;
        }
      }
    }
  }

  &-show {
    &-left {
      .logo {
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;

        img {
          max-width: 300px;
        }
      }
    }
    &-right {
      .informations {
        background-color: #fff !important;
      }

      &-actions {
        &-action {
          width: 100%;
          height: 175px;
          color: @mainBlueColor !important;
          display: block;
          padding: 30px;
          margin-top: 20px;
          border-radius: 20px;
          transition: background-color 0.3s ease-in-out;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
          background-color: #ececf7;

          &:hover {
            background-color: #d5d5f3;
          }

          i {
            margin-top: 28px;
            display: block;
          }
        }
      }
    }
  }

  &-list {
    &-item {
      max-width: 275px;
      margin: 0 auto 30px;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 5px 10px -5px #ccc;
      transition: box-shadow 0.3s ease-in-out;
      position: relative;

      &:hover {
        box-shadow: 0px 5px 10px 0px #ccc;

        .investments-list-item-drag {
          display: block;
        }
      }

      &-logo {
        height: 200px;
        overflow: hidden;

        img {
          margin: auto;
          width: 100%;
          display: block;
        }
      }

      &-drag {
        background: #fff;
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        height: 45px;
        width: 45px;
        font-size: 20px;
        padding: 6px 13px;
      }

      &-menu {
        padding: 20px;
        color: rgba(0, 0, 0, 0.85);

        &-name {
          display: block;
          font-size: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: rgba(0, 0, 0, 0.85) !important;
        }

        &-type {
          font-size: 10px;
          color: #b1cade;
        }

        button {
          color: rgba(0, 0, 0, 0.85) !important;

          .icon-more-vertical::before {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}

.investment-type-name {
  color: #b1cade;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 30px;
}

.choose-investment-btn {
  width: 175px;
  background-color: #fff !important;
  border-radius: 16px;
  margin-right: 10px;
  text-align: left;
  padding-left: 16px;

  .investment-name {
    width: 130px;
    overflow: hidden;
    vertical-align: bottom;
    text-overflow: ellipsis;
    margin-right: 2px;
  }

  .icon-arrow-simply-down::before {
    margin-top: 4px;
    margin-left: auto;
    float: right;
  }
  .icon-arrow-simply-top::before {
    margin-top: 2px;
    transform: rotate(90deg);
    float: right;
  }
}

.choose-investment-menu {
  border-radius: 16px;

  .ant-dropdown-menu-item-group-list {
    max-height: 175px;
    overflow: auto;
    margin-left: 0;
    padding-right: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;

    .ant-dropdown-menu-item {
      .ant-dropdown-menu-title-content {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .ant-dropdown-menu-item:hover {
      background-color: #f0f4f6;
    }

    .ant-dropdown-menu-item-selected {
      color: #3b4c60;
      background-color: #ffffff;

      &::after {
        content: "\0052";
        font-family: "icon";
        color: #15d55e;
        float: right;
        margin-left: 5px;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0f4f7;
      border-radius: 30px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #3b4c60;
      border-radius: 30px;
    }
  }

  .ant-dropdown-menu-item-group-title {
    font-family: Overpass;
    font-size: 10px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #c0d6e5;
    border-bottom: 1px solid #f0f4f6;
  }
}

.investments-list-wrapper {
  padding: 36px 24px;
}

@media only screen and (max-width: 350px) {
  .investments-list-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.total-surface-card {
  .ant-card-body {
    padding: 20px !important;
  }
}
