.main-counter-card-label-wrapper {
  color: #b1cade;
  font-size: 12px;
  margin-bottom: 10px;
}

.main-counter-card-value-wrapper {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
}

.main-counter-card-additional {
  position: absolute;
  top: 22px;
  font-size: 8px;
  font-weight: 700;
}

.main-counter-card-value {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.main-counter-card-value-wcopy {
  max-width: calc(100% - 30px);
}
.main-counter-card-value-nocopy {
  max-width: 100%;
}

.main-counter-card-value-copy {
  color: #587999;
  margin-left: 10px;
  margin-top: 3px;
  cursor: pointer;
  font-size: 20px;
}

.sub-counter-card-label-wrapper {
  color: #b1cade;
  font-size: 12px;
  margin-bottom: 10px;
}

.sub-counter-card-value-wrapper {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
}

.sub-counter-card-additional {
  position: absolute;
  top: 22px;
  font-size: 8px;
  font-weight: 700;
}

.sub-counter-card-value {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.sub-counter-card-value-wcopy {
  max-width: calc(100% - 30px);
}
.sub-counter-card-value-nocopy {
  max-width: 100%;
}

.sub-counter-card-value-copy {
  color: #587999;
  margin-left: 10px;
  margin-top: 3px;
  cursor: pointer;
  font-size: 20px;
}

@media only screen and (max-width: 576px) {
  .main-counter-card-value-wrapper {
    font-size: 18px;
  }

  .main-counter-card-value-copy {
    margin-top: -2px;
  }

  .sub-counter-card-value-wrapper {
    font-size: 18px;
  }

  .sub-counter-card-value-copy {
    margin-top: -2px;
  }
}
