.datagrid-columns-popover-wrapper {
  .ant-popover-title {
    color: #b1cade;
  }

  .ant-form-item-control-input {
    min-height: 28px;

    span {
      color: #587999;
    }
  }

  .ant-divider-horizontal {
    margin: 10px 0;
  }
}

.datagrid-filter-modal-button-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    max-width: 200px;
    width: 100%;
  }
}

.datagrid-wrapper {
  padding: 36px 24px;

  .datagrid-link {
    color: @mainBlackColor !important;

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 24px;
    max-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .datagrid-actions-wrapper {
    button {
      margin-left: 5px;
      i {
        padding-left: 5px;
      }
    }
  }

  .datagrid-columns-buttons {
    span {
      color: #fff;
    }
  }

  .ant-table {
    font-size: 12px;
    overflow-x: auto;
    border-radius: 0;
  }

  .ant-table-thead {
    th {
      font-weight: bold;
      background-color: #fff;
      white-space: nowrap;
      &.ant-table-column-has-sorters:hover,
      &.ant-table-column-sort.ant-table-column-has-sorters:hover {
        background-color: #f0f4f6;
      }
    }
  }
  .ant-table-tbody {
    td {
      background-color: #fff;

      .ant-btn:hover {
        background-color: #e3edf4;
        color: #3b4c60 !important;
      }
    }
    tr.ant-table-row:hover > td,
    tr.ant-table-row-selected > td {
      background-color: #f0f4f6;

      .ant-btn {
        background-color: #f0f4f6;
      }
    }

    tr.row-error-red > td {
      background-color: rgba(255, 0, 0, 0.1);

      .ant-btn {
        background-color: transparent;

        &:hover {
          background-color: rgba(255, 0, 0, 0.1) !important;
        }
      }
    }

    tr.ant-table-row.row-error-red:hover > td,
    tr.ant-table-row-selected.row-error-red > td {
      background-color: rgba(255, 0, 0, 0.2);

      .ant-btn {
        background-color: transparent;

        &:hover {
          background-color: rgba(255, 0, 0, 0.2) !important;
        }
      }
    }

    .ant-table-selection-column {
      width: 50px;
    }
  }

  .expanded-datagrid-row {
    .datagrid-wrapper {
      padding: 0;
    }

    & > td {
      padding: 0;
    }

    .ant-table-content table,
    td,
    td:hover,
    .ant-table-summary,
    .ant-table-summary td,
    .ant-table-thead th {
      background-color: #f0f4f6 !important;
    }

    .ant-table-tbody td {
      border-radius: 0;

      .datagrid-row-actions .ant-btn {
        background-color: #f0f4f6;
      }
    }

    .ant-table-summary td:first-child {
      border-top-left-radius: 0;
    }

    .ant-table-thead th {
      font-weight: bold;
    }
  }
}

.datagrid-columns-buttons-ok span {
  color: #fff !important;
}

.datagrid-columns-buttons-refresh {
  background-color: rgba(236, 67, 76, 0.1);
  border: none;
  color: rgba(236, 67, 76, 1);
}

.ant-table-cell {
  text-wrap: nowrap;
}

.ant-table-column-title {
  flex: none;
  margin-right: 5px;
}

.ant-table-column-has-sorters:before {
  content: none !important;
}

.ant-table-column-sorter {
  flex: 1;
  padding-bottom: 2px;
}

.button-show {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  border: 1px solid #e3edf4;
  background: transparent;

  i::before {
    margin: 0;
  }
}

.button-show:hover,
.button-show:focus {
  border-color: #8fb3d1;
  background: transparent;
}

.datagrid-row-actions {
  .ant-space-item {
    background: transparent;
    a {
      background: transparent;
      transition: none;

      .button-no-border {
        background: transparent !important;
      }

      .button-no-border:hover,
      .button-no-border:active {
        background-color: #e3edf4 !important;
      }
    }
  }
}

.ant-pagination-options {
  order: -1;
  margin-right: auto;
  margin-left: 0;

  &::before {
    content: 'Pozycji na stronie';
    margin-right: 10px;
    color: #b1cade;
    font-family: Overpass;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.datagrid-wrapper {
  .ant-table-wrapper {
    .ant-table.ant-table-empty {
      .ant-table-placeholder {
        .ant-table-cell {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

.all-members-btn {
  margin-left: 5px;
}

.all-members-btn:first-child {
  margin-left: 0;
}

.show-all-members-btn {
  background-color: #5353f4 !important;
  color: #fff !important;
}

@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: block;
    order: 0;
    margin-right: 0;
    width: 100%;
    text-align: right;
  }
}

@media only screen and (max-width: 500px) {
  .ant-col.datagrid-actions-wrapper,
  .ant-col.datagrid-mass-actions-wrapper {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 350px) {
  .all-members-btn {
    padding-left: 5px;
    padding-right: 5px;
  }

  // .ant-pagination-options {
  //   position: inherit;
  //   width: 250px;
  //   text-align: right;
  // }
}

.subcounter-history-datagrid {
  .icon-more-vertical {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;

    &:hover {
      color: #587999;
    }
  }
  .icon-more-vertical::before {
    transform: rotate(90deg);
  }
}

.datagrid-row-red {
  .ant-table-cell {
    background-color: #fdeced !important;

    .button-show {
      background-color: #fdeced !important;
    }

    .button-no-border {
      background-color: #fdeced !important;
    }
  }

  &:hover {
    .ant-table-cell {
      background-color: #fce3e4 !important;

      .button-show {
        background-color: #fce3e4 !important;
      }

      .button-no-border {
        background-color: #fce3e4 !important;
      }

      .button-no-border:hover {
        background-color: #fbd9db !important;
      }
    }
  }
}

.datagrid-row-yellow {
  .ant-table-cell {
    background-color: #fefcec !important;

    .button-show {
      background-color: #fefcec !important;
    }

    .button-no-border {
      background-color: #fefcec !important;
    }
  }

  &:hover {
    .ant-table-cell {
      background-color: #fefae3 !important;

      .button-show {
        background-color: #fefae3 !important;
      }

      .button-no-border {
        background-color: #fefae3 !important;
      }

      .button-no-border:hover {
        background-color: #fdf5c7 !important;
      }
    }
  }
}

.datagrid-row-green {
  .ant-table-cell {
    background-color: #f3fdf7 !important;

    .button-show {
      background-color: #f3fdf7 !important;
    }

    .button-no-border {
      background-color: #f3fdf7 !important;
    }
  }

  &:hover {
    .ant-table-cell {
      background-color: #e8fbef !important;

      .button-show {
        background-color: #e8fbef !important;
      }

      .button-no-border {
        background-color: #e8fbef !important;
      }

      .button-no-border:hover {
        background-color: #dcf9e7 !important;
      }
    }
  }
}

.datagrid-column-votes {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.expanded-datagrid-wrapper {
  padding-left: 50px;

  .ant-table-container table {
    thead {
      tr:first-child th:first-child {
        border-top-left-radius: 0px !important;
      }
    }
  }
}
